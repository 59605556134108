@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";

.modal {
  .paper {
    &.share-modal {
      border-radius: 4px;
      @include mobile {
        height: auto !important;
        border-radius: 4px;
        .modalContent {
          padding: 20px 0;
          .modalHeader {
            margin-bottom: 15px;
            padding: 0;
            .modalTitle {
              text-align: center;
              margin-bottom: 5px;
              font-size: 21px;
              color: var(--text-color);
            }
          }
          .modalBody {
            padding: 20px 10px 0;
            padding-left: 20px !important;
            button {
              outline: none;
            }
          }
        }
      }
    }
  }
}
