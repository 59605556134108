.title {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--text-color);
}
.scanQr {
  min-height: 200px;
  min-width: 200px;
  position: relative;
  img {
    margin-left: -20px;
  }
  .MuiCircularProgress-root {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    vertical-align: middle;
    animation: none;
  }
  .MuiCircularProgress-colorSecondary {
    color: var(--primary-color);
  }
}
.noAccountlink {
  max-width: 204px;
  font-size: 12px;
  display: flex;
  color: var(--input-text);
  margin: 20px auto 10px;
  height: 37px;
}
.qr {
  display: inline;
}

@media screen and (max-width: 480px) {
  .qr {
    display: none;
  }
}
