.back-btn {
  &.bio {
    top: 28px !important;
  }
}
.modalBody {
  margin: 0 10px;
  padding-bottom: 30px;
  color: var(--text-color);
  h6 {
    text-align: center;
    width: 100%;
    margin: 15px 0;
    font-size: 1.25em;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 15px;
  }
  p {
    padding: 10px;
    margin-top: 0;
    span {
      color: #9f9f9f;
    }
  }
  textarea {
    box-sizing: border-box;
    outline: none;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid var(--input-bg);
    font-size: 13px !important;
    display: block;
    width: 100%;
    height: 100px;
    resize: none;
    font-family: inherit;
    color: var(--input-text);
    background: var(--input-bg);
    border-color: transparent;
    margin-bottom: 20px;
    &::-webkit-input-placeholder {
      color: var(--placeholder);
    }
  }
  .txt {
    .MuiInput-underline:after,
    .MuiInput-underline:before {
      border: none !important;
    }
  }
}
.fullWidth {
  max-width: 100% !important;
}
