@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";
.modal {
  &.backdrop-transparent {
    .MuiBackdrop-root {
      // background: transparent;
    }
  }
  .paper {
    &.activeUser {
      background: var(--background-color-contrast);
      width: 200px;
      box-shadow: 0 16px 16px 0 rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
      min-height: auto;
      border-radius: 0;
      font-size: 14px;
      color: var(--notification-color);
      margin: 0;
      .modalContent {
        padding: 0;
        // .modalHeader {
        //   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        //   position: relative;
        //   padding: 14px;
        //   margin-bottom: 10px;
        //   cursor: default;
        //   display: flex;
        //   align-items: center;
        //   background-color: var(--background-color-contrast);
        //   img,
        //   svg {
        //     object-fit: cover;
        //     width: 45px;
        //     margin-right: 10px;
        //     height: 45px;
        //     border-radius: 45px;
        //     background-color: var(--profile-img-bg);
        //   }
        //   svg {
        //     padding: 3px;
        //   }
        //   .signin-user {
        //     .primary {
        //       fill: #fff;
        //       stroke: #fff;
        //     }
        //   }
        //   .modalTitle {
        //     h4 {
        //       font-size: 14px;
        //       margin: 0;
        //       text-align: left;
        //     }
        //     .user-mobile {
        //       font-size: 13px;
        //     }
        //   }
        // }
        .modalContent {
          // padding: 0;
          // flex-direction: column;
          ul {
            padding: 0 !important;
            .MuiListItem-root {
              svg {
                margin-right: 10px;
              }
            }
            // &:first-child {
            //   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            //   padding: 0;
            // }
            // li {
            //   display: flex;
            //   height: 45px;
            //   position: relative;
            //   padding: 3px 20px;
            //   cursor: pointer;
            //   align-items: center;
            //   &.logout {
            //     span {
            //       color: #ff3636;
            //     }
            //   }
            //   .MuiListItemText-root {
            //     margin: 0;
            //   }
            //   .MuiSwitch-root .MuiSwitch-track {
            //     opacity: 0.6;
            //     background-color: #909090;
            //   }
            //   .MuiTypography-body1 {
            //     // font-size: 13px;
            //     color: var(--title);
            //   }
            //   .icon {
            //     font-size: 17px;
            //     margin-right: 10px;
            //     width: 20px;
            //     color: #707070;
            //   }
            //   &.dark-theme-li {
            //     &:after {
            //       border-right: 2px solid;
            //       border-top: 2px solid;
            //       color: #707070;
            //       content: "";
            //       height: 0;
            //       padding: 4px;
            //       position: absolute;
            //       right: 35px;
            //       top: 15px;
            //       -webkit-transform: rotate(45deg);
            //       transform: rotate(45deg);
            //       width: 0;
            //     }
            //   }
            // }
          }
        }
      }
    }
    // .dark-theme-setting {
    //   position: relative;
    //   color: #707070;
    //   .account-header {
    //     background: #efefef;
    //     padding: 15px;
    //     display: flex;
    //     .title {
    //       text-align: center;
    //       width: 100%;
    //       font-size: 15px;
    //       color: #505050;
    //     }
    //     .back-btn {
    //       top: 10px;
    //     }
    //   }
    //   .menu-list {
    //     padding: 20px;
    //     padding-bottom: 20px;
    //     li {
    //       font-size: 13px;
    //       margin-bottom: 10px;
    //     }
    //   }
    // }
  }
}
// .dkt {
//   .activeUser {
//     .modalTitle {
//       color: var(--title);
//     }
//     svg {
//       path {
//         fill: #909090;
//       }
//     }
//     .dark-theme-setting {
//       color: #aaa !important;
//       .account-header {
//         background-color: #444;
//         .title {
//           color: #eee;
//         }
//         .back-btn {
//           &::before,
//           &::after {
//             background-color: #eee;
//           }
//         }
//       }
//     }
//   }
// }
// .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
//   background: #f50057 !important;
// }

.pref,
.logout {
  padding: 7px 15px !important;
  span {
    margin-left: 10px;
    font-size: 14px !important;
  }
}
.logout {
  span {
    color: #ff3636;
    // font-weight: 600;
  }
}
