@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.svg-icon.logo-original .primary {
  fill: var(--logo-primary);
}

.svg-icon.logo-original .secondary {
  fill: var(--logo-secondary);
}

.svg-icon.logo-original.nav-change .primary {
  fill: $iw7;
}

.svg-icon.logo-original.nav-change .secondary {
  fill: $iw7;
}
.dkt .svg-icon.logo-original.nav-change .primary {
  fill: $de;
}

.dkt .svg-icon.logo-original.nav-change .secondary {
  fill: $de;
}
.session-detail .svg-icon.logo-original .primary {
  fill: $de;
}
.session-detail .svg-icon.logo-original .secondary {
  fill: $de;
}
