@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

.grid-account {
  top: 110px;
  margin-bottom: 110px;
  overflow: auto;
}
.newpost {
  position: fixed !important;
  letter-spacing: 0.3px;
  top: 60px;
  z-index: 3;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%);
  background-color: var(--background-color-contrast) !important;
  padding: 6px 15px !important;
  border-radius: 15px !important;
  color: var(--text-color) !important;
  font-size: 13.5px;
  text-decoration: none !important;
  box-shadow: 2px 2px 6px #aaa;
  border: none !important;
  outline: 0 !important;
  svg {
    width: 16px;
    margin-right: 3px;
    position: relative;
    // top: 4px;
  }
}
.dkt {
  .newpost {
    box-shadow: none !important;
  }
}
.coming-soon {
  // color: #7d7d7d;
  // padding: 7em !important;
  // text-align: center;
  // height: calc(100vh - 108px);
  // span {
  //   top: 50%;
  //   left: 50%;
  //   position: absolute;
  //   transform: translate(-50%, -50%);
  // }
  color: var(--main-color);
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  // align-items: center;
  .no-content {
    margin: auto;
    text-align: left;
    font-size: 16px;
    padding-bottom: 40px;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include mobile {
      max-width: 80%;
    }
  }
  .you-may {
    // margin: auto;
    text-align: center;
    font-size: 16px;
    // margin-left: 20px;
    margin-bottom: 1.5em;
  }
  div.links {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    button {
      padding: 4px;
      margin-right: 16px;
      color: #333;
      text-decoration: none;
    }
  }
}
.page-title {
  margin-top: 0;
  padding: 5px 0;
  font-size: 18px;
  color: var(--text-color);
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include mobile {
    margin: 5px 0;
    padding-left: 10px;
  }
}
.scroll {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 9;
  cursor: pointer;
  svg {
    width: 30px;
  }
}

.uploadBtn {
  position: fixed !important;
  bottom: 25px;
  right: 25px;
  color: #fff !important;
  background-color: #32c5ff !important;
  border-radius: 100%;
  box-shadow: 0 -3px 24px 0 rgba(50, 197, 255, 0.49);
  width: 80px;
  height: 80px;
  z-index: 99;
  svg {
    width: 2em;
    height: 2em;
  }
  &:hover {
    background-color: #129acf !important;
  }
  @include mobile {
    display: none !important;
  }
}
