@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.svg-icon.signin-user .primary {
  // stroke: $iw7;
  stroke: var(--main-color);
  fill: none;
  stroke-miterlimit: 10;
}
.profileImage .svg-icon.signin-user .primary {
  fill: #fff;
  stroke: #fff;
  stroke-miterlimit: 10;
}
.comment-container {
  .comment-head,
  .comment-text-box {
    .user-img .primary {
      fill: #fff;
      stroke: #fff;
      stroke-miterlimit: 10;
    }
  }
}
.active .svg-icon.signin-user .primary {
  stroke: $lp;
  fill: $lp;
  stroke-miterlimit: 10;
}

.svg-icon.user .primary {
  fill: $bgColor;
}

.svg-icon.user .secoundary {
  fill: #bcbcbc;
}

.dkt .footer-navigation .active .svg-icon.signin-user .primary {
  stroke: #eee;
  fill: #eee;
}
.footer-navigation .svg-icon.signin-user .primary {
  fill: none;
  stroke: #707070;
}

.footer-navigation .active .svg-icon.signin-user .primary {
  stroke: $lp;
  fill: $lp;
}
