@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.NavItem .svg-icon.catcheck .primary {
  fill: var(--sidebar-icon-fill-color);
}
.NavItem .svg-icon.untik .primary {
  fill: #6c7b8a;
}
