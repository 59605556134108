@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.NavItem .svg-icon.formats .primary {
  // fill: var(--svg-sidebar-color);
  fill: var(--main-color);
}

// .NavItem.NavItemCategories:hover .svg-icon.formats .primary {
//   fill: #6394b4;
// }

.NavItem.NavItemCategories.active .svg-icon.formats .primary {
  fill: #fff;
}

.drawerClose .sidebarStyle .NavItem.NavItemCategories.active .svg-icon.formats .primary {
  fill: var(--sidemenu-toggle-active);
}
