@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";
.modal {
  .paper {
    &.language-modal {
      height: 80% !important;
      max-width: 650px;
      @include mobile {
        height: 90% !important;
        overflow: hidden;
        width: 100vw !important;
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .modalHeader {
        margin-bottom: 0;
      }
      .modalBody {
        padding: 0;
        overflow: hidden;
        height: 60vh;
        @include mobile {
          padding: 3% 2%;
          height: calc(100% - 150px) !important;
        }
        .search-language {
          width: 100%;
          height: 35px;
          left: 10px;
          input {
            display: block;
            width: 100%;
            height: 30px;
            box-sizing: border-box;
            line-height: 26px;
            font-size: 12px !important;
            padding: 0 10px;
            color: var(--input-text);
            border-radius: 4px;
            border: 1px solid var(--input-bg);
            outline: 0;
            background-color: var(--input-bg);

            &::placeholder {
              color: var(--placeholder);
            }
          }
        }

        .language-container {
          &.c-height {
            height: 60vh;
            // max-height: calc(100vh - 240px);
            overflow-y: auto;
            padding-bottom: 30px;
            h4 {
              margin-left: 10px;
              color: var(--text-color);
            }
            .no-content {
              color: var(--input-text);
              font-size: 13px;
            }
            .language-label {
              float: left;
              border-radius: 8px;
              color: #262626;
              height: 30px;
              padding: 0 25px !important;
              margin: 10px !important;
              position: relative;
              background: #f6f6f6;
              cursor: pointer;
              font-size: 13px;
              @include desktop {
                padding: 8px 25px !important;
              }

              @include mobile-and-gplands {
                margin: 2%;
                padding: 0px 7px;
                line-height: 33px;
                min-height: 33px;
                height: 33px !important;
              }
            }
            input.active + label {
              background-color: var(--select-count-lang-btn-bg);
              border-color: $lp;
              border-radius: 2px;
              @include mobile-and-gplands {
                border-color: $lp;
              }
            }
            input[type="checkbox"]:checked,
            input[type="checkbox"]:not(:checked) {
              display: none;
            }
            input[type="checkbox"]:checked + label {
              color: #a1a5d3;
              background: #e6e5ff;
              .tick {
                background: #a1a5d3;
                height: 20px;
                width: 20px;
                position: absolute;
                top: -7px;
                right: -7px;
                border-radius: 50%;
                &:before {
                  content: "\2713";
                  color: #fff;
                  position: absolute;
                  top: -5px;
                  right: 5px;
                  @include desktop {
                    top: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
      .modalFooter {
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: transparent;
        .w-50 {
          width: 90%;
        }
        button {
          width: 200px;
          border-radius: 35px;
          margin-top: 0;
          @include mobile {
            width: 100%;
            border-radius: 35px;
          }
        }
        .pbar {
          margin-top: 10px;
          padding: 0 10px;
          // @include mobile {
          display: none;
          // }
          img {
            width: 100%;
            height: 7px;
          }
        }
        .error {
          margin-top: 0;
          display: block;
          text-align: center;
        }
        .submit:disabled {
          border-color: #f56e9d;
          background-color: #f56e9d;
          cursor: default;
        }
      }
    }
  }
}
.heading-modal {
  color: var(--text-color);
  text-align: center;
  border-bottom: 1px solid #d9d9d9;
}
