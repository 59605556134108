@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";

.share-modal {
  // border-radius: 4px;
  padding: 25px;
  z-index: 1;
  box-shadow: 0 16px 16px 0 rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
  border-radius: 4px;
  background-color: var(--background-color-contrast);
  @include mobile {
    height: auto !important;
    border-radius: 4px;
    .modalContent {
      // padding: 20px 0;
      .modalHeader {
        margin-bottom: 15px;
        padding: 0;
        .modalTitle {
          font-size: 21px;
          color: var(--text-color);
        }
      }
      .modalBody {
        // padding: 20px 10px 0 0;
        // padding-left: 20px !important;
        padding: 0 !important;
        margin-left: 0;
        .MuiGrid-container {
          margin-bottom: 0;
        }
        button {
          outline: none;
        }
      }
    }
  }
  .modalHeader {
    margin-bottom: 0 !important;
    .modalTitle {
      color: var(--text-color);
      margin: 5px 0 0 0;
      font-size: 20px;
      font-weight: normal;
    }
  }
  .modalBody {
    padding: 0 !important;
  }
  .modalButtons {
    display: inline-block;
    justify-content: space-between;
  }
  input,
  input:focus {
    box-sizing: border-box;
    outline: none;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid var(--background-color);
    font-size: 12px !important;
    resize: none;
    color: var(--text-color);
    background: var(--background-color);
    border-color: transparent;
    &::-webkit-input-placeholder {
      color: var(--placeholder);
    }
  }
  div.btn {
    display: inline;
    .btnCopy {
      background: $lb;
      color: #fff;
      border: 1px solid $lb;
      outline: none;
      cursor: pointer;
      font-size: 14px;
      padding: 6px 20px;
      display: inline-block;
      text-align: center;
      margin-left: 10px;
      border-radius: 4px;
    }
  }
}
