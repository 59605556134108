@import "../../assets/scss/variables.scss";

.svg-icon {
    transition: 250ms;
  }

  .svg-icon.download-content .primary {
    fill:none;
    stroke:#A5A5A5;
  }

  
  