.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background: #32c5ff !important;
  opacity: 1 !important;
}
.Mui-checked {
  color: #fff !important;
  fill: #fff !important;
}
.modalBody {
  margin: 0 10px;
  padding-bottom: 30px;
  color: var(--text-color);
  h6 {
    text-align: center;
    width: 100%;
    margin: 15px 0;
    font-size: 1.25em;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 15px;
  }
  p {
    padding: 10px;
    margin-top: 0;
  }
  .blk {
    background-color: var(--background-color);
    padding: 25px;
    margin: 0 15px;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    position: relative;
    .mature-switch {
      vertical-align: middle;
      position: absolute;
      margin-top: 25px;
      right: 25px;
      &.m-red {
        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
          background: #ff4e4e !important;
        }
      }
    }
  }
}
