.themeModal {
  min-height: 70vh !important;
  max-height: 70vh !important;
  position: absolute !important;
  overflow-y: auto !important;
  bottom: -10px;
  .modalBody {
    margin: 0 10px;
    padding-bottom: 30px;
    h6 {
      text-align: center;
      width: 100%;
      margin: 15px 0;
      font-size: 1.25em;
    }
    .temp-box {
      width: 40vw;
      height: 40vw;
      // background-color: khaki;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-size: 2em;
      &.selected {
        box-shadow: 0 2px 24px 0 #666;
      }
    }
  }
}
