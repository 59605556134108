@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

.drawer {
  width: var(--sidebar-width);
  flex-shrink: 0;
  white-space: "nowrap";
  &.drawerOpen {
    width: var(--sidebar-width);
  }
  .sidebarStyle {
    transition: 0.26s ease-in-out !important;
    min-height: 550px;
    -webkit-transition: 0.26s ease-in-out !important;
    border-right: 0;
    top: 55px;
    width: var(--sidebar-width);
    padding-right: 3px;
    height: calc(100% - 56px);
    background-color: var(--background-color-contrast);
    // overflow: hidden;
    @include mobile {
      > button {
        border-radius: unset;
      }
    }
    &:hover {
      overflow-y: auto;
    }
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    .nestedRoot {
      width: 100%;
      max-width: 360px;
      padding: 0;
      margin-top: 50px;
    }
    .sliderGenre {
      // margin-top: 20px !important;
      margin-top: 10px !important;
      .action-btn .NavItem {
        padding: 1px 0;
        .checked-icon {
          visibility: hidden;
          .catcheck {
            visibility: visible;
            width: 16px;
          }
          .untik {
            width: 16px;
          }
        }
        &:hover {
          .checked-icon,
          .catpinunfill {
            visibility: visible;
          }
        }

        .catpin {
          visibility: visible;
          width: 12px;
          margin-right: 10px;
        }
        .catpin.active {
          visibility: visible;
        }
        .catpinunfill {
          width: 12px;
          margin-right: 10px;
          visibility: hidden;
        }
      }
    }
    .MuiCollapse-container {
      .NavItem {
        &.active {
          background: 0 0 !important;
          color: $lp;
        }
      }
    }
    .NavItem {
      padding: 4px 0;
      // padding: 0;
      // line-height: 38px;
      border-radius: 4px;
      color: var(--sidebar-color) !important;
      // &:hover {
      //   color: #6394b4;
      // }
      &.active {
        // background-color: $lp;
        color: var(--sidebar-active-color) !important;
      }
      .MuiButtonBase-root {
        padding: 0;
        // padding-left: 28px;
        margin-left: 28px;
        border-radius: 0;
      }
      .NavItemText {
        margin: 0;
        font-size: 13.5px;
        padding-left: 7px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.nested {
          padding-left: 75px;
        }
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      > svg {
        font-size: initial;
      }
    }
    .privacyContact {
      position: relative;
      bottom: 0;
      .storelink {
        padding-left: 30px;
        margin-left: -10px;
        .bg {
          background-color: var(--background-misc);
          padding: 2px 5px;
          border-radius: 5px;
          width: 140px;
          svg:nth-child(1) {
            margin: 3px 0 0 0;
          }
        }
      }
      .NavItem .NavItemText {
        padding-left: 25px;
        // padding-left: 30px;
      }
    }
    .privacyContact1 {
      position: absolute;
      bottom: 0;
      .storelink {
        padding-left: 30px;
        margin-left: -10px;
        .bg {
          background-color: var(--background-misc);
          padding: 2px 5px;
          border-radius: 5px;
          width: 140px;
          svg:nth-child(1) {
            margin: 3px 0 0 0;
          }
        }
      }
      .NavItem .NavItemText {
        padding-left: 25px;
        // padding-left: 30px;
      }
    }
    a {
      &.active {
        .sidebar-icon {
          #Path {
            fill: var(--sidebar-icon-fill-color) !important;
            stroke: var(--sidebar-icon-fill-color) !important;
            @include mobile {
              stroke: #fff !important;
              fill: none !important;
            }
          }
        }
      }
    }
  }
  .sidebar-icon {
    #Path {
      fill: transparent !important;
      @include mobile {
        fill: none !important;
      }
    }
  }
  &.drawerClose {
    width: 60px;
    overflow-x: hidden;
    .sidebarStyle {
      // width: 60px !important;
      width: 70px !important;
      box-shadow: none;
      padding-right: 0;
      .privacyContact,
      .MuiDivider-root {
        display: none;
      }
      .NavItem {
        height: 55px;
        &.NavItemPlayStore,
        &.NavItemAppStore {
          display: none;
        }
        &.active {
          background-color: transparent;
          .MuiTypography-root {
            color: #454545 !important;
          }
        }
        &:before,
        &:after {
          top: 9px;
        }
        .MuiButtonBase-root {
          margin-top: -15px;
        }
        .MuiTypography-root {
          position: absolute;
          font-size: 10px;
          font-style: normal;
          // bottom: 0;
          top: 33px;
          // left: 60%;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 50px;
          text-align: center;
        }
        &.NavItemCountry,
        &.NavItemLanguage {
          .NavItemText {
            width: 60px;
            height: 15px;
            // position: absolute;
            overflow: hidden;
            top: 45px;
            // .MuiTypography-root {
            //   height: 15px;
            //   bottom: 19px;
            // }
          }
        }
      }
    }
  }
}

.bottom-menu {
  color: #a8a8a8 !important;
}

@media screen and (max-width: 480px) {
  .sidebarStyle {
    top: 0 !important;
    height: 100% !important;
    width: 70% !important;
  }
  .userIcon-sidebar {
    object-fit: cover;
    width: 50px;
    border-radius: 50%;
    height: 50px;
    box-shadow: 1px 1px 1px #ccc;
    margin-top: 10px;
    vertical-align: bottom;
  }
  .name-label {
    padding-left: 10px;
    display: inline-block;
    .username {
      font-size: 15px;
      margin-left: 0;
    }
    .edit-profile {
      color: var(--primary-color);
      font-size: 12px;
      display: block;
      text-align: left;
      width: 100%;
    }
  }

  .logout-sidebar-mobile {
    position: absolute !important;
    bottom: 20px !important;
    left: 30px;
    color: var(--danger-color) !important;
  }
}
