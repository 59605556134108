@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";
.dialog-modal {
  &.view-profile-image-modal {
    .MuiPaper-root {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      width: 100%;
      height: 100%;
      padding: 0;
      max-width: 640px;
      max-height: 85%;
      border-radius: 0;
      @include mobile {
        max-height: 65%;
      }
      .closeBtn {
        &:before,
        &:after {
          background-color: #ddd;
        }
        &:hover {
          background: transparent;
        }
      }
      > div {
        width: 100%;
        height: 100%;
        position: relative;
        .profile-image {
          min-height: 130px;
          width: 100%;
          object-fit: cover;
          margin: 0 auto;
          top: 50%;
          left: 50%;
          height: 100%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          position: absolute;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            background-color: #222;
            border-radius: 4px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            object-fit: cover;
          }
        }
      }
    }
  }
}
