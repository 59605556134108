@import "../../../assets/scss/mixin.scss";

.grid {
  position: relative;
  .blank-tile {
    width: 240px;
    margin: 10px 5px;
    border-radius: 3px;
    background-color: #eee;
    border: 1px solid #f3f3f3;
    overflow: hidden;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.shine {
  width: 240px;
  max-width: 100%;
  margin: 10px 5px;
  border-radius: 3px;
  border: 1px solid #f3f3f3;
  background-color: #efefef;
  background-image: linear-gradient(to right, #efefef 0%, #e6e6e6 20%, #efefef 40%, #efefef 100%);
  background-repeat: no-repeat;
  background-size: 800px;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  @include mobile {
    width: 98%;
  }
}
.dkt {
  .shine {
    border: 1px solid #222222;
    background-color: #222831;
    background-image: linear-gradient(to right, #222831 0%, #222838 20%, #222831 40%, #222831 100%);
  }
}
