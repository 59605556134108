.grid-container {
  background-color: var(--background-color-contrast);
  border-radius: 4px;
  margin-bottom: 25px !important;
  .grid-section {
    padding: 15px 10px;
    border-bottom: 1px solid #e5e5e5;
    label {
      color: var(--text-color);
    }
    .txt-rght {
      label {
        color: #b0b7c3;
        cursor: pointer;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

.select-photo {
  margin-bottom: 35px;
  label {
    color: #b0b7c3;
    font-size: 14px;
    margin-top: 5px;
  }
}
.add-photo {
  text-align: center;
  label {
    color: #0a1f44;
    font-size: 14px;
  }
}
.mobile-label {
  color: #0a1f44 !important;
  width: 100% !important;
  margin-left: 0 !important;
  text-align: center;
  display: block;
}
.dkt {
  .mobile-label {
    color: #fff !important;
  }
}

.MuiSwitch-root {
  right: -20px;
  transform: translateY(-50%);
  width: 80px !important;
  top: 5px;
  .MuiSwitch-track {
    opacity: 1;
    background-color: var(--switch-track-background);
    width: 48px;
    height: 25px;
    border-radius: 15px;
  }
  .MuiSwitch-thumb {
    height: 18px;
    width: 18px;
    margin-left: 8px;
    margin-top: 6px;
  }
}
.matureSwitch {
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background: var(--danger-color) !important;
    opacity: 1;
  }
  .Mui-checked {
    color: #fff !important;
    fill: #fff !important;
  }
}