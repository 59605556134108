.dialog-modal {
  &.content-scroll-modal {
    .MuiPaper-root {
      .modal-body {
        p {
          font-size: 14px;
          color: var(--text-color);
          line-height: 23px;
          text-align: center;
          select {
            margin-top: 15px;
            width: 48px;
            height: 30px;
            line-height: 26px;
            padding: 0 5px 0 0;
            border: 1px solid var(--input-bg);
            border-radius: 4px;
            color: var(--input-text);
            font-size: 13px !important;
            box-shadow: 1px 1px 1px 1px #eee;
            outline: 0;
            background-color: var(--input-bg);
            cursor: pointer;
            option {
              height: 30px;
              line-height: 28px;
            }
          }
        }
      }
      .modal-footer {
        .MuiDialogActions-root {
          padding: 0;
          text-align: right;
          .MuiButtonBase-root {
            display: inline-block;
            margin: 0 15px;
            &:last-child {
              margin-right: 0;
            }
            &:disabled {
              color: var(--menu-btn-color);
            }
          }
        }
      }
    }
  }
}
