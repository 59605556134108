@import "../../assets/scss/mixin.scss";

.no-content-found {
  width: 40%;
  margin: 50px auto;
  @include mobile {
    width: 80%;
  }
  h2 {
    color: #3b464d !important;
    font-size: 30px;
    font-weight: 400;
    margin: 30px auto;
  }
  p {
    color: #6c7b8a !important;
    font-size: 22px;
    max-width: 85%;
    line-height: 1.7em;
    margin: 30px auto;
    @include mobile {
      max-width: 100%;
      font-size: 16px;
    }
  }
  .no-content-btn {
    margin: 30px auto;
    width: 160px;
  }
}
