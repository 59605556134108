@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixin.scss";

.show-more-detail-modal {
  .show-more-container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;

    .text-light2 {
      width: 50%;
      height: 100%;
      max-width: 1280px;
      position: relative;

      @include mobile {
        width: 100%;
      }

      .back-btn-white {
        background: #fff !important;
        &::before,
        &::after {
          background: #fff !important;
        }
      }

      .back-btn {
        z-index: 1;
        position: relative;
        top: 0;
        left: 20px;
        background: var(--back-btn-color);
        border: none;
        color: #fff;
        width: 20px;
        height: 2px;
        font-size: 0;
        border-radius: 10px 0 0 10px;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        @include mobile {
          // top: 0;
        }

        &::before {
          content: "";
          position: absolute;
          left: -1px;
          width: 12px;
          height: 2px;
          background: var(--back-btn-color);
          transform: rotate(-45deg);
          top: -4px;
        }

        &::after {
          content: "";
          position: absolute;
          left: -1px;
          width: 12px;
          height: 2px;
          background: var(--back-btn-color);
          transform: rotate(45deg);
          top: 4px;
        }

        // &:hover {
        //   background: #eee;
        // }
      }

      .textarea-light {
        position: relative;
        height: 100%;

        .image-text {
          display: flex !important;
          justify-content: center;
          height: 100%;
          flex-direction: column;
          color: #cfcfcf;
          text-align: left;
          padding: 0 25px;

          > div {
            overflow: hidden;
            flex-wrap: wrap;
            text-overflow: ellipsis;
          }

          .description {
            font-size: 13px;
            line-height: 30px;
            margin-top: 10px;
            max-height: calc(100vh - 250px);
            overflow: auto;
            color: #fff !important;
            word-break: break-word;

            p {
              color: #fff !important;

              span,
              a, strong {
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}
