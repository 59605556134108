@import "../../../../assets/scss/mixin.scss";

.avataar {
    .p-50 {
        padding: 20px 50px 0;
        @include mobile {
            padding: 15px;
        }
    }
    .avataarImg {
        margin: 0 auto;
        @include mobile {
            margin-bottom: 15px;
        }
    }
    .sub-header {
        @include mobile {
            left: 0;
        }
    }
    .avataar-btn {
        border: 1px solid #32c5ff;
        border-radius: 50px;
        color: #32c5ff;
        padding: 5px 20px;
        font-size: 12px;
        background-color: transparent;
        outline: none;
        line-height: 1.75;
        margin: 6px 10px;
        cursor: pointer;
        @include mobile {
            margin: 8px 6px;
        }
    }
    .avsubmit-btn {
        margin: 20px 0;
    }
}
.paper.avataar {
    @include mobile {
        width: 100% !important;
        border-radius: 0 !important;
        height: 100% !important;
    }
}
