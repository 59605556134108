@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";

.story {
  display: flex !important;
  justify-content: center;
  // height: 100%;
  flex-direction: column;
  color: #fff;
  text-align: left;
  h3 {
    margin: 0;
    font-size: 16px;
    color: #eee;
    padding: 0 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .description {
    font-size: 13px;
    // line-height: 18px;
    line-height: 30px;
    padding-top: 10px;
    // max-height: calc(100vh - 350px);
    // max-height: 300px;
    overflow: hidden;
    color: #aaa !important;
    // height: 100%;
    padding: 0 25px;

    @include mobile-and-gplands {
      max-height: calc(100vh - 500px);
    }
    p {
      color: #fff !important;
      height: 100%;
      overflow: hidden;
      strong {
        color: #fff !important;
      }
      a {
        color: #fff !important;
      }
      span {
        // background-color: transparent !important;
        // color: #aaa !important;
        // word-break: break-word;
        p {
          height: auto;
        }
      }
    }
    .view-more {
      height: initial;
      font-size: 14px;
      line-height: 20px;
      color: #eee;
    }
  }

  .text-readmore {
    // position: absolute;
    font-size: 14px;
    // bottom: 100px;
    right: 25px;
    background: transparent;
    color: #fff;
    border: transparent;
    cursor: pointer;
    outline: transparent;

    position: relative;
    text-align: right;
    width: 100%;
    padding-top: 10px;
    // @include mobile-and-gplands {
    //   bottom: 150px;
    // }
  }
  .detail-arrow {
    position: relative !important;
    bottom: 0;
    svg {
      position: absolute;
      right: 22%;
      bottom: -30px;
    }
  }
}
