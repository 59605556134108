label {
  margin-right: 0 !important;
}
.spl-btn.icon.active {
  width: 60px;
}
.spl-btn.icon {
  width: 60px;
}
.filter-bar .text-right {
  float: right;
}
