.upload-btn {
    color: var(--text-color) !important;
  a {
    color: var(--primary-color) !important;
    text-decoration: none;
  }
  button{
    color: #32c5ff;
  }
}
