@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";
.text-box-popup {
  display: flex !important;
  justify-content: center;
  // height: 100%;
  flex-direction: column;
  color: #fff;
  text-align: left;
  // padding: 0 25px;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  height: calc(100vh - 200px);

  @include mobile-and-gplands {
    padding: 0 20px;
    height: calc(100vh - 240px);
    position: absolute;
    top: 50px;
    width: 100%;
    display: block !important;
    left: 0;
    transform: none;
  }
  h3 {
    margin: 0;
    font-size: 16px;
    color: #eee;
  }

  .description {
    font-size: 13px;
    // line-height: 18px;
    line-height: 30px;
    margin-top: 10px;
    // max-height: calc(100vh - 350px);
    max-height: calc(100vh - 250px);
    overflow: hidden;
    color: #aaa !important;
    @include mobile-and-gplands {
      max-height: calc(100vh - 500px);
    }
    > div {
      margin-top: 0;
    }
    p {
      color: #aaa !important;
      strong {
        color: #aaa !important;
      }
      span {
        background-color: transparent !important;
        color: #aaa !important;
      }
    }
    .view-more {
      height: initial;
      font-size: 14px;
      line-height: 20px;
      color: #eee;
    }
  }
  .detail-arrow {
    bottom: -5px;
  }
}
.web-light {
  // cursor: pointer;
  @include mobile-and-gplands {
    padding: 0;
    height: calc(100% - 106px);
    // height: 87%;
    // display: block !important;
  }
  .img-container {
    // top: 5%;

    @include mobile-and-gplands {
      // top: 7%;
    }
  }
  img {
    position: relative;
    top: auto;
    left: auto;
    transform: initial;
    margin: 0 auto;
    height: 130px;
    max-height: 130px;
    width: 100%;
    // top: 5%;
    width: 100%;
    object-fit: cover;
    @include mobile-and-gplands {
      // position: absolute;
      // width: 100%;
      height: 90px;
      // object-fit: cover;
    }
  }
  .link-box {
    //background-color: #0355ff;
    // border-radius: 10px;
    padding: 25px 45px;
    cursor: pointer;
    @include mobile {
      padding: 25px;
      margin: 0;
      border-radius: 0;
    }
    img {
      cursor: pointer;
      border: 3px solid #fff;
    }
    h3 {
      margin-top: 5px;
      font-size: 28px;
      font-weight: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      @include mobile {
        font-size: 20px;
      }
    }
    span {
      font-weight: normal;
      @include mobile {
        font-size: 0.8em;
      }
    }
    h5 {
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 0;
      padding-bottom: 3px;
      @include mobile {
        margin-top: 15px;
      }
      svg {
        width: 20px;
        fill: #fff;
        margin-right: 5px;
        margin-bottom: -3px;
      }
    }
  }
  .singleLine {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    color: #fff;
  }
  .lb-webcard {
    // max-height: 50%;
    // height: 35%;
    text-align: justify;
    z-index: 2;
    // background-image: linear-gradient(rgba(238, 238, 238, 1), rgba(245, 245, 245, 1));
    padding: 25px 45px;
    overflow: hidden;
    color: #666 !important;
    background: #fff;
    position: relative;
    height: 80%;
    @include mobile-and-gplands {
      padding: 20px 25px;
      // top: 20%;
      // background-image: linear-gradient(rgba(238, 238, 238, 1), rgba(245, 245, 245, 1));
      // min-height: 93%;
      z-index: 0;
      margin: 0;
      border-radius: 0;
      overflow: hidden;
      background: #fff;
    }
    > p {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #fff;
      margin-bottom: 0;
      height: 40px;
    }
    .web-gradient {
      height: 45%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(rgba(256, 256, 256, 0.3), rgba(256, 256, 256, 0.7), rgba(256, 256, 256, 0.95));
    }
    h3 {
      margin-top: 10px;
      font-size: 17px;
      margin-bottom: 15px;
      // color: #fff;
      color: #666 !important;

      @include mobile-and-gplands {
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 0;
        color: #666 !important;
      }
    }
    span {
      font-size: 18px;
      letter-spacing: 0.3px;
      // color: #fff;
      line-height: 25px;
      color: #666 !important;
      font-family: "HelveticaNeue";
      @include mobile-and-gplands {
        font-size: 16px;
        line-height: 22px;
        color: #666 !important;
      }
    }
    .web-icon {
      position: absolute;
      // width: 165px;
      padding: 6px 0;
      bottom: 10px;
      background: #fff;
      border-radius: 20px;
      -webkit-tap-highlight-color: transparent;
      transform: translate(-50%);
      left: 50%;
      border: none;
      box-shadow: 1px 3px 8px #aaa !important;
      @include mobile-and-gplands {
        width: auto;
      }
      a {
        padding: 6px 46px;
        text-decoration: none;
        @include mobile-and-gplands {
          width: auto;
        }
      }
      img {
        width: 18px;
        height: 18px;
        position: relative;
        top: 2px;
      }
      .svg-icon.web-link .primary {
        fill: #505050;
      }
      span {
        color: #505050;
        font-size: 18px;
        position: relative;
        padding: 0 5px;
      }
    }
  }
  .iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    @include mobile {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 90%;
      border: none;
    }
  }
}
