@import "../../../../assets/scss/mixin.scss";

.followersModal {
  .modalHeader {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  .modalBody {
    max-height: 50vh;
    overflow-y: auto;
    position: relative;
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    h6 {
      width: 100%;
      margin: 0;
      font-size: 1.25em;
    }
    .follow-list {
      display: block;
      margin-top: 20px;
      width: 100%;
      overflow-y: auto;
      .follow-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .follow-name {
          color: var(--text-color);
          max-width: 80%;
          cursor: pointer;
          img {
            vertical-align: middle;
            border-radius: 50%;
            margin-right: 10px;
            width: 30px;
            height: 30px;
          }
        }
        .follow-btn {
          .f-btn {
            background: var(--primary-color);
            color: #fff;
            text-align: center;
            cursor: pointer;
            border-radius: 50px;
            width: 100px;
            font-size: 0.8em;
            &:hover {
              background: var(--primary-color) !important;
            }
            &.f-btn-dis {
              color: #a6a6a6 !important;
              background: #e4e4e4 !important;
              &:hover {
                color: #a6a6a6 !important;
                background: #e4e4e4 !important;
              }
            }
          }
        }
      }
    }
  }
}

.shimmer-follower {
  // margin: 10px 5px;
  // border-radius: 3px;

  @include mobile {
    width: 98%;
  }
  .follow-item {
    .follow-name {
      .img {
        width: 30px;
        height: 30px;
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 5px;
        border: 1px solid #f3f3f3;
        background-color: #efefef;
        background-image: linear-gradient(
          to right,
          #efefef 0%,
          #e2e2e2 20%,
          #efefef 40%,
          #efefef 100%
        );
        background-repeat: no-repeat;
        background-size: 800px;
        display: inline-block;
        position: relative;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeholderShimmer;
        -webkit-animation-timing-function: linear;
      }
      span {
        width: 100px;
        height: 15px;
        display: inline-block;
        border: 1px solid #f3f3f3;
        background-color: #efefef;
        background-image: linear-gradient(
          to right,
          #efefef 0%,
          #e2e2e2 20%,
          #efefef 40%,
          #efefef 100%
        );
        background-repeat: no-repeat;
        background-size: 800px;
        display: inline-block;
        position: relative;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeholderShimmer;
        -webkit-animation-timing-function: linear;
      }
    }
    .follow-btn {
      width: 100px;
      height: 35px;
      display: inline-block;
      border-radius: 25px;
      border: 1px solid #f3f3f3;
      background-color: #efefef;
      background-image: linear-gradient(
        to right,
        #efefef 0%,
        #e2e2e2 20%,
        #efefef 40%,
        #efefef 100%
      );
      background-repeat: no-repeat;
      background-size: 800px;
      display: inline-block;
      position: relative;
      -webkit-animation-duration: 1s;
      -webkit-animation-fill-mode: forwards;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-name: placeholderShimmer;
      -webkit-animation-timing-function: linear;
    }
  }
}
.dkt {
  .shimmer-follower {
    .follow-btn {
      border: 1px solid #222222;
      background-color: #222831;
      background-image: linear-gradient(
        to right,
        #222831 0%,
        #222838 20%,
        #222831 40%,
        #222831 100%
      );
    }
    .follow-name {
      img {
        border: 1px solid #222222;
        background-color: #222831;
        background-image: linear-gradient(
          to right,
          #222831 0%,
          #222838 20%,
          #222831 40%,
          #222831 100%
        );
      }
      span {
        border: 1px solid #222222;
        background-color: #222831;
        background-image: linear-gradient(
          to right,
          #222831 0%,
          #222838 20%,
          #222831 40%,
          #222831 100%
        );
      }
    }
  }
}
@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
