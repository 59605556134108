@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variables.scss";
.profile-filter {
  //   padding: 20px;
  //   width: 100%;
  //   @include mobile {
  //     padding: 20px 0;
  //   }
  label {
    width: 49%;
    display: inline-block;
    font-size: 18px;
    color: var(--text-color);

    @include mobile {
      // display: none;
      width: auto;
      padding-top: 4px;
    }
    span {
      text-transform: capitalize;
    }
  }
  .filters {
    text-align: right;
    width: 31%;
    display: inline-block;
    @include mobile {
      // display: block;
      // width: 100%;
      width: auto;
      float: right;
    }
    > label {
      float: left;
      width: auto;
    }
    .spl-btn {
      background-color: var(--search-filter-bar-item-background-color);
      border-radius: 4px;
      color: var(--search-filter-bar-text-color);
      box-shadow: none;
      font-size: 14px;
      padding: 10px 14px;
      margin-left: 3px;
      margin-right: 3px;
      max-width: 50px;
      text-align: center;
      &.icon {
        color: #6c7b8a;
        fill: #6c7b8a;
        font-size: 0.8rem;
        vertical-align: middle;
        padding: 9px 12px;
        background-color: transparent;
      }
      &.active {
        background-color: var(--search-filter-bar-select-item-background-color);
        color: var(--search-filter-bar-select-item-text-color);
        &:hover {
          background-color: var(--search-filter-bar-select-item-background-color) !important;
          color: var(--search-filter-bar-select-item-text-color) !important;
        }
      }
      :hover {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}
.profile-filter2 {
  .filters .spl-btn.icon {
    @include mobile {
      width: auto;
    }
  }
  > label {
    @include mobile {
      padding-left: 5px !important;
    }
    h4 {
      font-weight: normal;

      @include mobile {
        margin-top: 5px;
      }
    }
  }
}
