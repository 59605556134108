@import "../../assets/scss/mixin.scss";

.editProfilePage {
  // position: fixed;
  // top: 0;
  // left: 0;
  // height: 100%;
  // width: 100%;
  // z-index: 100;
  // opacity: 1;
  // background: var(--background-color);
  .sub-header {
    background: #4f4bce;
    color: #fff !important;
  }
  .settingBody {
    margin-top: -30px;
    .bg-box {
      background: #4f4bce;
      height: 100px;
      width: 100%;
    }
    .account-settings {
      padding: 15px;
      @include mobile {
        margin-bottom: 30px;
        margin-top: -70px;
      }
      h3 {
        color: var(--text-color);
      }
      .MuiList-root {
        background-color: var(--background-color-contrast);
        border-radius: 4px;
        padding: 0 !important;
        .MuiListItem-root {
          display: block;
          position: relative;
          color: var(--text-color);
          padding: 0 10px;
          border-bottom: 1px solid var(--input-bg) !important;
          -webkit-tap-highlight-color: transparent;
          .MuiListItemText-root {
            margin: 0;
            .MuiTypography-root {
              font-size: 16px;
              color: var(--text-color);
              display: block;
              text-decoration: none;
              line-height: 40px;
              margin-bottom: 0;
            }
          }
          .inputform,
          select,
          button {
            position: absolute;
            top: 5px;
            right: 10px;
            width: 50%;
            border: none;
            padding: 5px;
            appearance: none;
            background: none;
            text-align: right;
            display: inherit;
            border-radius: 4px;
            color: var(--text-color);
            &:focus {
              outline: none;
              // background-color: var(--background-color);
            }
          }
        }
      }
      .subtext {
        display: block;
        color: var(--text-color);
        font-size: 12px;
        line-height: 13px;
        margin-bottom: 10px;
        max-width: 80%;
      }
      .MuiSwitch-root {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 80px;
        height: 50px;
        .MuiSwitch-track {
          opacity: 1;
          background-color: var(--switch-track-background);
          width: 48px;
          height: 25px;
          border-radius: 15px;
        }
        .MuiSwitch-thumb {
          height: 18px;
          width: 18px;
          margin-left: 8px;
          margin-top: 6px;
        }
      }
    }
  }
}

.link-setting {
  margin-top: 50px;
  h3 {
    color: var(--text-color);
  }
  .MuiListItem-root {
    padding: 4px 16px;
    span {
      // color: var(--text-color);
    }
  }
}
.nonactive {
  color: #b0b7c3;
}
.changePhoto {
  display: none;
}
.editprofileimage {
  text-align: center;
  margin: 20px 0 !important;
  position: relative;
  .changePhotoProfile {
    display: none;
  }
  .profileImage {
    img {
      border-radius: 50%;
    }
  }
  .bg-camera {
    // background: #000;
    // height: 110px;
    // position: absolute;
    // top: 0;
    // border-radius: 50%;
    // left: 35%;
    // width: 110px;
    // opacity: 0.25;
  }
  .camera {
    font-size: 3em;
    color: #fff;
    position: absolute;
    top: 40px;
    left: 46%;
  }
  .change-profile {
    position: absolute;
    top: 60px;
    right: 10px;
    color: #0d55cf;
  }
}
.link-settings .MuiListItem-root span {
  color: #fff;
}

.themebtn {
  width: 60px !important;
  padding: 5px 15px !important;
  right: 10px;
  top: 10px;
  position: absolute;
  border-radius: 4px;
  .inner-color {
    background: #fff;
    color: #fff;
    border-radius: 2px;
    height: 10px;
  }
}
.themeselect {
  padding: 5px 15px !important;
  right: 0px;
  top: 5px;
  position: absolute;
}
.back-btn {
  &.btn2 {
    background-color: #fff;
    position: absolute;
    top: 25px;
    &::after,
    &::before {
      background-color: #fff;
    }
    @include mobile {
      // left: 10px;
      // width: 40px;
    }
  }
}
.nonactive {
  span {
    color: #b0b7c3 !important;
  }
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background: #32c5ff !important;
}
.Mui-checked {
  color: #fff !important;
  fill: #fff !important;
}
.bluetext {
  color: var(--primary-color) !important;
}
.countryselector {
  position: absolute;
  top: 7px;
  right: 15px;
  .flag {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    img {
      margin-left: -6px;
    }
  }
}
