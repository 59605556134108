@import "../../../../assets/scss/mixin.scss";

.slider a.nextButton {
  z-index: 11;
  right: 0;
  // padding: 100px 10px 100px 60px;
  padding: 40px 12px;
  border-radius: 10px 0 0 10px;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: auto;

  // transform: translateY(-50%) scale(1) !important;
  svg {
    color: #fff;
    polygon {
      fill: #fff;
      opacity: 1 !important;
    }
  }
}

.slider a.previousButton {
  z-index: 11;
  left: 0;
  // padding: 100px 60px 100px 10px;
  padding: 40px 12px;
  border-radius: 0 10px 10px 0;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  svg {
    color: #fff;
    polygon {
      fill: #fff;
      opacity: 1 !important;
    }
  }
  &.disabled {
    display: none;
  }
}
.desktop_img .detail-arrow {
  position: absolute;
  bottom: -30px;
  svg {
    position: absolute;
    right: 22%;
  }
}
.oval {
  background-size: 100%;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  cursor: pointer;
  @include mobile {
    width: 25px;
    height: 25px;
  }
}
.top-label {
  position: absolute;
  padding-top: 2px;
  color: #6c7b8a;
  font-size: 1.2em;
  margin-left: 0;
  @include mobile {
    width: 85% !important;
  }
}
.MuiGrid-container {
  margin: 20px 0;
}
.right-align {
  text-align: right;
}
