@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";
.web-more-link {
  .MuiPaper-root {
    display: block;
    position: absolute;
    bottom: 9%;
    right: 25.6%;
    background: var(--background-color);
    color: #4b4b4b;
    // padding: 15px 20px;
    padding: 15px 0;

    border-radius: 3px;
    z-index: 2;
    font-size: 16px;
    width: 206px;
    margin: 0;
    @include mobile {
      left: 0;
      right: auto;
      width: 100%;
      bottom: 0;
    }
    .MuiDialogTitle-root {
      padding: 0 20px;
      padding-bottom: 10px;
      color: var(--text-color);
      .MuiTypography-root {
        font-size: inherit;
        line-height: initial;
      }
    }
    .MuiList-root {
      font-size: 14px;
      cursor: pointer;
      padding: 0;
      .MuiListItem-root {
        // padding: 2px 20px;
        padding: 8px 20px;
        display: flex;
        align-items: flex-start;
        // margin: 16px 0;
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          margin-right: 8px;
        }
        .link-text {
          p {
            margin: 0;
            font-size: 13px;
            color: var(--text-color);
          }
          span {
            font-size: 12px;
            color: var(--text-color);
          }
        }
      }
    }
  }
}

.contentOption {
  padding: 10px;
  border-radius: 4px;
  max-width: 250px;
  font-size: 14px;
  box-shadow: 0 16px 16px 0 rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
  color: #4e5d78;
  .oval {
    margin-right: 0px;
    img {
      width: 21px;
      height: 21px;
      vertical-align: middle;
      margin-top: 5px;
    }
  }
  svg {
    width: 20px;
    margin-right: 9px;
    vertical-align: middle;
  }
  span {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  p {
    margin-bottom: 0;
    display: inline-block;
    margin: 0 !important;
  }
  .report {
    p {
      color: #ff3636 !important;
    }
  }
}
