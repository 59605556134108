@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";
.modal {
  .paper {
    &.country-modal {
      // height: calc(100vh - 30px) !important;
      max-width: 720px;
      height: 500px;
      @include mobile-and-gplands {
        // height: 90% !important;
        overflow: hidden;
      }
      .modalHeader {
        margin-bottom: 0;
        @include mobile {
          padding-left: 0 !important;
        }
        .modalTitle {
          text-align: center;
          margin-bottom: 5px !important;
        }
      }
      .modalBody {
        padding: 15px 20px 10px;
        @include mobile {
          padding: 3% 2%;
          height: calc(100% - 105px);
        }
        .search-country {
          width: 100%;
          // height: 35px;
          left: 10px;
          input {
            display: block;
            width: 100%;
            // height: 30px;
            box-sizing: border-box;
            line-height: 26px;
            font-size: 12px !important;
            padding: 5px 10px;
            color: var(--input-text);
            border-radius: 4px;
            border: 1px solid var(--input-bg);
            outline: none;
            background-color: var(--input-bg);
            &::placeholder {
              color: var(--placeholder);
            }
          }
        }

        .country-container {
          &.c-height {
            max-height: 280px;
            margin-top: 10px;
            overflow-y: auto;
            @include mobile {
              max-height: 100vw;
              height: auto;
            }
            .no-content {
              color: var(--primary-color);
              font-size: 13px;
              text-align: center;
            }
            .country-label {
              float: left;
              border-left: 3px solid transparent;
              color: var(--select-count-lang-btn-color);
              padding: 7px;
              margin: 10px;
              box-shadow: 1px 1px 2px #ccc;
              position: relative;
              background: var(--background-color-contrast);
              cursor: pointer;
              font-size: 12px;
              border-radius: 2px;
              @include mobile-and-gplands {
                margin: 5px;
                padding: 0px 7px;
                line-height: 33px;
                min-height: 33px;
                height: 33px !important;
              }
            }
            input.active + label {
              background-color: var(--select-count-lang-btn-bg);
              border-color: $lp;
              border-radius: 2px;
              @include mobile-and-gplands {
                border-color: $lp;
              }
            }
            input[type="checkbox"]:checked,
            input[type="checkbox"]:not(:checked) {
              display: none;
            }
            input[type="checkbox"]:checked + label {
              // background-color: #f5f5f5;
              border-left: 3px solid $lp !important;
              border-radius: 2px;
            }
          }
        }
      }
      .modalFooter {
        padding: 10px;
        @include mobile {
          position: absolute;
          width: 100%;
          bottom: 0;
        }
        button {
          width: 200px;
          margin-top: 0;
          @include mobile {
            width: 100%;
          }
        }
        .submit:disabled {
          border-color: var(--primary-color);
          background-color: var(--primary-color);
          cursor: default;
        }
      }
    }
  }
}

.countryFlag {
  width: 24px;
  height: 24px;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
  img {
    margin-left: -6px;
  }
}

.heading-modal {
  color: var(--text-color);
  text-align: center;
  border-bottom: 1px solid #d9d9d9;
}
.back-btn {
  top: 25px !important;
  position: absolute !important;
}
