@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variables.scss";

#notfound {
  position: relative;
  height: calc(100vh - 63px);
  width: 100%;
  @include mobile {
    height: calc(100vh - 80px);
  }
}
#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding: 15px;
}
.notfound .notfound-404 {
  position: relative;
  height: 220px;
}
.notfound .notfound-404 h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 186px;
  margin: 0px;
  background: $lp;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase;
}
.notfound h2 {
  font-size: 33px;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 25px;
  letter-spacing: 3px;
  color: var(--input-text);
}
.notfound p {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 25px;
  color: var(--input-text);

  a {
    text-decoration: none;
    color: var(--text-color);
  }
}
