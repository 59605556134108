@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.svg-icon.uparrow .primary {
  stroke: var(--main-color);
  fill: none;
  stroke-miterlimit: 10;
}

