@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";
.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
  width: 100% !important;
}
.camera-take-photo-modal {
  .camera-take-photo-paper {
    width: 100%;
    height: 100%;
    background: transparent !important;
    @include mobile {
      background-color: #000 !important;
      .back-btn {
        background-color: #fff !important;
      }
      .back-btn::before,
      .back-btn:after {
        background-color: #fff !important;
      }
    }

    .closeBtn {
      top: 10px;
      &::before,
      &::after {
        background-color: #fff;
      }
    }
    .modalContent {
      padding: 0 !important;
      margin-bottom: -3px;
      @include mobile {
        height: 100vh;
        display: flex;
        display: -webkit-flex;
        align-items: center;
      }
      > img {
        @include mobile {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .react-html5-camera-photo {
        @include mobile {
          // position: absolute;
          // top: 50%;
          // transform: translateY(-50%);
          width: 100%;
        }
        #container-circles {
          bottom: 75px;
          @include mobile {
            bottom: 0;
            width: 100%;
            left: 0;
          }
        }
      }
      img,
      video {
        border-radius: 4px;
      }
      #outer-circle {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 60px;
        cursor: pointer;
        @include mobile {
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          position: fixed;
          bottom: 10px;
        }
        #inner-circle {
          margin: -30px 0 0 -22px;
          @include mobile {
            position: fixed;
            bottom: 10px;
          }
        }
      }
      .retake-btn {
        background: #eee;
        border: 0;
        outline: none;
        cursor: pointer;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        color: #fff;
        position: absolute;
        right: 5%;
        bottom: 15px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
        @include mobile {
          background-color: transparent;
          position: absolute;
          bottom: 0;
          right: 0;
          top: auto;
          left: auto;
        }
        svg {
          margin-top: 5px;
        }
      }
      .camera-btn {
        border: none;
        cursor: pointer;
        bottom: 15px;
        outline: none;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);

        @include tab {
          width: 60px;
          height: 60px;
          background-color: #eee;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
          border-radius: 50%;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
        }
        @include mobile {
          background: none;
          color: #a5a5a5;
          font-size: 15px;
          outline: none;
          border-radius: 50%;
          height: 60px;
          width: 60px;
          bottom: 0;
          left: 0;
          position: absolute;
        }
        svg {
          margin-top: 5px;
        }
      }
      .camera-actions {
        position: relative;
        @include mobile {
          position: initial;
        }
        .mobile-camera {
          justify-content: space-between;
          display: flex;
        }
      }

      .switch-btn {
        display: none;
        @include mobile {
          display: block;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 50%;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          width: 60px;
          position: absolute;
          bottom: 5px;
          right: 5px;
          cursor: pointer;
          .btn {
            // height: 44px;
            // width: 44px;
            // background: #fff;
            // border-radius: 50%;
            background: transparent;
            border: 0;
            outline: none;
          }
        }
      }
    }
  }
}
