@import "../../../../assets/scss/mixin.scss";

.modal {
  .title {
    margin-bottom: 5px;
  }
  .paper {
    // height: 500px !important;
    // max-width: 720px !important;
    @include mobile {
      height: auto !important;
    }
    &.login-modal {
      height: auto !important;
      .p-50 {
        // padding: 40px 50px 30px 50px;
        padding: 60px 70px 50px 70px;
        @include mobile {
          padding: 35px;
        }
      }
      .modalTitle {
        margin-bottom: 40px;
        color: var(--text-color);
        @include mobile {
          display: none;
        }
      }
      .orText {
        border-left: 1px solid #f1f1f1;
        height: 35%;
        margin-top: 72px;
        @include mobile {
          display: none;
        }

        div {
          &:last-child {
            p a {
              text-decoration: none;
              color: var(--text-color);
            }
          }
        }
      }
      .MuiGrid-container {
        margin: 0 !important;
      }
    }
    .modalFooter {
      font-size: 13px;
      text-align: center;
      color: var(--secondary-color);
      padding: 13px 0;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: var(--background-misc);
      border-radius: 0 0 10px 10px;
      p {
        margin: 0;
        a {
          text-decoration: none;
        }
        span {
          color: var(--primary-color) !important;
        }
      }
    }
  }
}
.submitButton {
  font-size: 16px;
  max-width: 320px !important;
}
.p-50 {
  padding: 50px;
}
