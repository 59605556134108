@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

.drawer {
  width: var(--sidebar-width);
  flex-shrink: 0;
  white-space: "nowrap";

  &.drawerOpen {
    width: var(--sidebar-width);
  }

  .sidebarStyle {
    transition: 0.26s ease-in-out !important;
    min-height: 550px;
    -webkit-transition: 0.26s ease-in-out !important;
    border-right: 0;
    top: 55px;
    width: var(--sidebar-width);
    padding-right: 3px;
    height: calc(100% - 56px);
    background-color: var(--background-color-contrast);
    // overflow: hidden;

    &:hover {
      overflow-y: auto;
    }

    .nestedRoot {
      width: 100%;
      max-width: 360px;
      padding: 0;
      margin-top: 50px;
    }

    .MuiCollapse-container {
      .NavItem {
        &.active {
          background: 0 0 !important;
          color: $lp;
        }
      }
    }

    .NavItem {
      padding: 4px 0;
      // padding: 0;
      // line-height: 38px;
      border-radius: 4px;
      color: var(--sidebar-color);

      &.logout-sidebar-mobile {
        color: var(--danger-color) !important;
      }
      // &:hover {
      //   color: #6394b4;
      // }
      &.active {
        // background-color: $lp;
        color: #000;
      }

      .MuiButtonBase-root {
        padding: 0;
        // padding-left: 28px;
        margin-left: 28px;
        border-radius: 0;
      }

      .NavItemText {
        margin: 0;
        font-size: 13.5px;
        padding-left: 7px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.nested {
          padding-left: 75px;
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      > svg {
        font-size: initial;
      }
    }

    .privacyContact {
      //position: absolute;
      bottom: 0;

      .storelink {
        padding-left: 30px;

        .bg {
          background-color: var(--background-misc);
          padding: 2px 5px;
          border-radius: 5px;
          width: 140px;

          svg:nth-child(1) {
            margin: 3px 0 0 0;
          }
        }
      }

      .NavItem .NavItemText {
        padding-left: 30px;
      }
    }
  }

  &.drawerClose {
    width: 60px;
    overflow-x: hidden;

    .sidebarStyle {
      // width: 60px !important;
      width: 70px !important;
      box-shadow: none;
      padding-right: 0;

      .privacyContact,
      .MuiDivider-root {
        display: none;
      }

      .NavItem {
        height: 55px;

        &.NavItemPlayStore,
        &.NavItemAppStore {
          display: none;
        }

        &.active {
          background-color: transparent;

          .MuiTypography-root {
            color: var(--sidemenu-toggle-active);
          }
        }

        &:before,
        &:after {
          top: 9px;
        }

        .MuiButtonBase-root {
          margin-top: -15px;
        }

        .MuiTypography-root {
          position: absolute;
          font-size: 10px;
          font-style: normal;
          // bottom: 0;
          top: 33px;
          // left: 60%;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 50px;
          text-align: center;
        }

        &.NavItemCountry,
        &.NavItemLanguage {
          .NavItemText {
            width: 60px;
            height: 15px;
            // position: absolute;
            overflow: hidden;
            top: 45px;
            // .MuiTypography-root {
            //   height: 15px;
            //   bottom: 19px;
            // }
          }
        }
      }
    }
  }
}

.sidebar-icon {
  color: #6c7b8a !important;
  fill: #6c7b8a !important;

  &.logoutmobile {
    vertical-align: middle;
    margin-right: 10px;
  }
}

.bottom-menu {
  color: #a8a8a8 !important;
}

@media screen and (max-width: 480px) {
  .sidebarStyle {
    top: 0 !important;
    height: 100% !important;
    width: 70% !important;
  }

  .userIcon-sidebar {
    object-fit: cover;
    width: 50px;
    border-radius: 50%;
    height: 50px;
    box-shadow: 1px 1px 1px #ccc;
    margin-top: 10px;
  }

  .sidebar-head {
    display: flex;
    width: 100%;
    align-items: center;
    .name-label {
      padding-left: 10px;
      vertical-align: 15px;

      .username {
        font-size: 16px;
        margin-left: 0;
        padding-left: 0;
      }

      .edit-profile {
        color: var(--primary-color);
        font-size: 14px;
        display: block;
        text-align: left;
        width: 100%;
        // padding-left: 1px;
      }
    }
  }

  .logout-sidebar-mobile {
    position: absolute !important;
    bottom: 20px !important;
    left: 30px;
    color: var(--danger-color) !important;
  }
}

.mobile-menu {
  padding: 15px !important;
  color: var(--text-color) !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 8px !important;
  width: 80% !important;

  &.active {
    background-color: #32c5ff;

    // box-shadow: 0 -3px 24px 0 rgba($color: #32c5ff, $alpha: 0.5);
    span {
      color: #fff !important;
    }

    .sidebar-icon {
      #Path {
        stroke: #fff !important;

        @include mobile {
          stroke: #fff !important;
        }
      }
    }
  }

  &:hover {
    background-color: #fff;
    box-shadow: none;
    color: var(--text-color) !important;
  }

  .MuiButtonBase-root {
    margin-left: 10px !important;
  }

  svg {
    margin-right: 10px !important;
  }
}

.lft {
  justify-content: flex-start !important;
  border-radius: 0 !important;
}

.notlogin {
  text-align: center;
  padding-top: 40px;

  label {
    display: block;
    margin: 0;
    color: var(--text-color);
  }

  .submitButton {
    width: 75%;
    margin: 10px auto !important;
  }
}

.dkt {
  .username {
    color: #fff !important;
  }
}

// iphone
// @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
//   .sidebarStyle {
//     top: 0 !important;
//     height: 100% !important;
//     width: 70% !important;
//   }
// }

// @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
//   .sidebarStyle {
//     top: 0 !important;
//     height: 100% !important;
//     width: 70% !important;
//   }
// }

// @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
//   .sidebarStyle {
//     top: 0 !important;
//     height: 100% !important;
//     width: 70% !important;
//   }
// }
// @supports (-webkit-touch-callout: none) {
//   .sidebarStyle {
//     top: 0 !important;
//     height: 100% !important;
//     width: 70% !important;
//   }
// }

// @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
//   .sidebarStyle {
//     top: 0 !important;
//     height: 100% !important;
//     width: 70% !important;
//   }
// }

// @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
//   .sidebarStyle {
//     top: 0 !important;
//     height: 100% !important;
//     width: 70% !important;
//   }
// }

// @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
//   .sidebarStyle {
//     top: 0 !important;
//     height: 100% !important;
//     width: 70% !important;
//   }
// }

//safari 10.1+ƒ
@media not all and (min-resolution: 0.001dpcm) {
  .sidebarStyle {
    @include mobile {
      top: 0 !important;
      height: 100% !important;
      width: 70% !important;
      overflow: visible !important;
    }
  }
}

.roundedimg {
  border-radius: 50% !important;

  @include mobile {
    width: 64px;
    height: 64px;
  }
}
