@import "../../../../assets/scss/mixin.scss";

.modal {
  .title {
    margin-bottom: 5px;
  }
  .meme-modal {
    max-width: 800px;
  }
  .meme-modal.paper {
    @include mobile {
      width: 100vw !important;
      border-radius: 0;
      .p-50 {
        height: 100% !important;
      }
    }
  }
  .paper {
    height: auto;
    &.meme-modal {
      height: auto !important;
      @include mobile {
        height: 100% !important;
      }
      .p-50 {
        padding: 40px 50px 30px 50px;
        // height: 560px;
        height: calc(100vh - 50px);
        overflow-y: auto;
        @include mobile {
          padding: 35px 13px;
        }
        &::-webkit-scrollbar-thumb {
          border: none;
          background-color: none;
          border-radius: 10px;
          background-clip: padding-box;
        }
        &::-webkit-scrollbar {
          // width: 5px;
          display: none;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        .h50 {
          height: 50px;
        }
        .mt10 {
          margin-top: 10px;
          text-align: center;
          max-height: 630px;
        }
        .memeCanvas {
          height: auto !important;
        }
        .meme {
          position: relative;
          width: 100%;
          max-height: 300px;
          margin: auto;
          background-color: transparent;
          #myTh2 {
            top: 0;
            left: 50%;
            margin: 1rem 0 0 0;
            transform: translate(-50%, 0);
            position: absolute;
          }
        }

        .meme > img {
          max-width: 100%;
          max-height: 300px;
        }
        #myTh2 {
          position: absolute;
          z-index: 9;
          text-align: center;
        }

        #myTh2header {
          padding: 0px;
          cursor: move;
          z-index: 10;
          margin: 0;
        }
        #myBh2 {
          position: absolute;
          z-index: 9;
          text-align: center;

          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0);
          position: absolute;
        }

        #myBh2header {
          padding: 10px;
          cursor: move;
          z-index: 10;
          color: #333;
          margin: 0;
        }
        /* .meme > h2 {
          position: absolute;
          width: 80%;
          text-align: center;
          left: 50%;
          //transform: translateX(-50%);
          margin: 15px 0;
          padding: 0 5px;
          font-family: impact, sans-serif;
          font-size: 1em;
          cursor: move;
          text-transform: uppercase;
          color: white;
          letter-spacing: 1px;
          // text-shadow: 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 0 2px 0 #000, 2px 0 0 #000,
          //   0 -2px 0 #000, -2px 0 0 #000, 2px 2px 5px #000;
        } */

        /* .meme > .bottom {
          bottom: 10px;
        }

        .meme > .top {
          top: 0;
        } */
        .MuiGrid-item {
          padding: 0 10px;
        }
        // .meme-form {
        //   width: 90%;
        //   margin: 20px auto;
        //   display: flex;
        //   justify-content: space-between;
        // }

        // .meme-form > input {
        //   width: 45%;
        //   height: 40px;
        // }

        // .meme-form > button {
        //   border: none;
        //   font-family: VT323, monospace;
        //   font-size: 25px;
        //   letter-spacing: 1.5px;
        //   color: white;
        //   background: #6441a5;
        // }

        // .meme-form > input::-webkit-input-placeholder {
        //   font-family: VT323, monospace;
        //   font-size: 20px;
        //   text-align: cen;
        // }
        // .meme-form > input::-moz-placeholder {
        //   font-family: VT323, monospace;
        //   font-size: 20px;
        //   text-align: cen;
        // }
        // .meme-form > input:-ms-input-placeholder {
        //   font-family: VT323, monospace;
        //   font-size: 20px;
        //   text-align: cen;
        // }
        // .meme-form > input:-moz-placeholder {
        //   font-family: VT323, monospace;
        //   font-size: 20px;
        //   text-align: cen;
        // }
        input,
        textarea {
          box-sizing: border-box;
          outline: none;
          padding: 10px;
          border-radius: 4px;
          border: 1px solid var(--input-bg);
          font-size: 13px !important;
          display: block;
          width: 100%;
          resize: none;
          color: var(--input-text);
          background: var(--input-bg);
          border-color: transparent;
          margin-bottom: 15px;
          &::-webkit-input-placeholder {
            color: var(--placeholder);
          }
        }
        .submitButton {
          background: #32c5ff;
          color: #fff;
          outline: none;
          margin: 15px auto;
          font-size: 16px;
          padding: 12px;
          display: block;
          text-align: center;
          width: 100%;
          border-radius: 50px;
          border-style: none;
          max-width: 350px;
        }
        .size-btn {
          border: 1px solid #32c5ff;
          border-radius: 50px;
          color: #32c5ff;
          padding: 3px 15px;
          font-size: 12px;
          margin-right: 5px;
          background-color: transparent;
        }
        .MuiTab-root {
          padding: 6px 12px;
          overflow: hidden;
          position: relative;
          font-size: 0.875rem;
          max-width: 264px;
          min-width: 72px;
          box-sizing: border-box;
          min-height: 48px;
          text-align: center;
          flex-shrink: 0;
          font-family: lato;
          font-weight: 500;
          line-height: 1.75;
          white-space: normal;
          text-transform: uppercase;
          @include mobile {
            min-width: auto;
          }
        }
        .MuiGridListTile-imgFullHeight {
          // left: 50%;
          // height: 50%;
          // position: relative;
          // transform: translateX(-50%);
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }
}
