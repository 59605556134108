.modal {
  .paper {
    // min-height: 300px !important;
    // max-width: 720px !important;
    &.preference-modal {
      height: auto !important;
      position: relative;
      .p-50 {
        padding: 0 50px 30px 50px !important;
        &.maxHeight {
          max-height: 560px;
          height: 560px;
          overflow-y: auto;
          display: block;
          &::-webkit-scrollbar-thumb {
            border: none;
            background-color: none;
            border-radius: 10px;
            background-clip: padding-box;
          }
          &::-webkit-scrollbar {
            // width: 5px;
            display: none;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
        }
      }
    }
  }
}

.pref-btn {
  padding-top: 10px;
  text-align: right;
  margin-left: 10px;
  .spl-btn {
    background-color: var(--background-color);
    border-radius: 4px;
    color: var(--search-filter-bar-text-color);
    box-shadow: none;
    margin: 0 2px;
    &:hover {
      background-color: var(--background-color) !important;
      box-shadow: none;
    }
    &.active {
      background-color: var(--search-filter-bar-select-item-background-color);
      color: var(--search-filter-bar-select-item-text-color);
      &:hover {
        background-color: var(--search-filter-bar-select-item-background-color) !important;
        color: var(--search-filter-bar-select-item-text-color) !important;
      }
    }
  }
  .history-btn {
    border: 1px solid #32c5ff;
    border-radius: 50px;
    // box-shadow: 0 2px 24px 0 rgba(50, 197, 255, 0.26);
    color: #32c5ff;
    padding: 3px 15px;
    font-size: 12px;
    &:hover {
      background-color: #fff;
      // color: #fff;
    }
  }
}
.mr-10 {
  margin-left: 10px !important;
}
.mt-10 {
  margin-top: 10px;
}
legend {
  &.MuiFormLabel-root {
    color: #000;
  }
}
.MuiGrid-container {
  &.py-10 {
    padding: 10px !important;
  }
}
.bold {
  color: var(--text-color);
  margin-left: 0;
  padding-bottom: 15px;
  display: block;
  font-family: "Lato-Bold" !important;
}
.MuiFormControl-root {
  width: 100%;
}
.pref-block {
  background-color: var(--pref-block-background-color);
  border-radius: 4px;
  color: var(--pref-block-color);
  padding: 10px;
  margin-left: 5px;
  width: fit-content;
  margin-top: 34px;
  font-size: 12px;
}
.mx-3 {
  margin: 2em 0;
}

.pref-modal-head {
  position: fixed;
  width: 100%;
  height: 100px;
  max-width: 620px;
  background: var(--background-color-contrast);
  z-index: 1;
  .pref-btn button {
    width: auto;
    float: initial;
  }
}
.pref-modal-body {
  margin-top: 100px;
  .submitbutton {
    &:disabled {
      background-color: #6c7b8a;
      cursor: default;
    }
  }
}
.bottom-btn {
  bottom: 60px;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
}
