@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.NavItem .svg-icon.apple .primary {
  fill: var(--main-color);
}

// .NavItem.NavItemAppStore:hover .svg-icon.apple .primary {
//   fill: #6394b4;
// }

.NavItem.NavItemAppStore.active .svg-icon.apple .primary {
  fill: #fff;
}

.NavItem .svg-icon.android .primary {
  fill: var(--main-color);
}

// .NavItem.NavItemPlayStore:hover .svg-icon.android .primary {
//   fill: #6394b4;
// }

.NavItem.NavItemPlayStore.active .svg-icon.android .primary {
  fill: #fff;
}
