@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 200ms;
}

.svg-icon.more-option-content .primary {
  // fill: #a5a5a5;
  fill: #6b7b8b;
}
.svg-icon.more-option-content-white .primary {
  fill: #fff;
}
