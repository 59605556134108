.dialog-modal {
  &.copy-link-modal {
    .MuiPaper-root {
      .MuiTypography-root {
        margin-bottom: 5px;
      }
      .modal-body {
        .normal-text {
          font-size: 14px;
          color: var(--input-text);
          background-color: var(--input-bg);
          line-height: 23px;
          input {
            display: block;
            width: 100%;
            height: 30px;
            padding: 0 5px;
            font-size: 12px !important;
            border-radius: 2px;
            border: 1px solid var(--input-bg);
            color: var(--input-text);
            background-color: var(--input-bg);
            cursor: default;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
