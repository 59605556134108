@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";
.videoAds {
  top: calc(50% - 65px) !important;
  // left: auto !important;
  // transform: none !important;
  // height: calc(100vh - 130px) !important;
  height: 100% !important;
  @include mobile {
    max-height: calc(100vh - 200px);
  }
}
.video-react {
  // top: 50%;
  // left: 50%;
  // position: absolute;
  // -webkit-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  // -webkit-tap-highlight-color: transparent;
  // height: 0;
  // padding-top: 60%;
  // width: 100%;

  // position: relative;
  // margin-top: 25vh;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: calc(100vh - 150px);
  height: 100%;
  z-index: $zIndex;
  pointer-events: auto;

  @include mobile {
    max-height: calc(100vh - 210px);
  }
  // top: 7%;

  &:hover,
  &:active {
    .video-controls {
      opacity: 1;

      // display: flex;
      visibility: visible;
    }
  }

  .video-react-video {
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 80% !important;
    // height: 60% !important;
    margin: 0 auto;

    video {
      &:focus {
        outline: none;
      }
    }
  }

  .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    // margin: -25px 0 0 -25px;
    color: white;
    transform: translate(-50%, -50%);
    animation: none;
  }

  .video-react-big-play-button {
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(43, 51, 63, 0.1) !important;
    border-radius: 0 !important;
    border: none !important;
    font-size: 3em;
    line-height: 1.5em;
    display: block;
    position: absolute;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;

    &.big-play-button-hide {
      display: none;
    }
  }

  .video-replay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    padding: 15px;
    // padding: 7px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
}

.video-ptop {
  // padding-top: 50%;
  @include mobile {
    // padding-top: 60%;
  }
}
.progressReplay {
  position: absolute;
  top: calc(50% - 55px);
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-controls {
  // padding: 5px 15px;
  padding: 10px 15px;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;

  // display: none;
  display: flex;
  visibility: hidden;
  // visibility: visible;
  flex-direction: column;
  // align-items: flex-end;

  .play-pause-mobile-btn {
    position: absolute;
    transform: translate(-50%, -50%);
    border: none;
    background-color: transparent;
    color: white;

    top: 50%;
    left: 50%;

    img {
      width: 35px;
      height: 35px;
    }
  }

  .play-pause-btn {
    background-color: transparent;
    border: none;

    padding: 0px;
    /* border-radius: 50%;
    width: 30px;
    height: 30px; */

    img {
      width: 15px;
      height: 15px;
      cursor: pointer;
      padding: 3px 0px 0px 1px;
    }

    &:focus {
      outline: none;
    }
  }

  .top-control-btn {
    position: absolute;
    transform: translate(-50%, -50%);
    border: none;
    background-color: transparent;
    color: white;
    top: calc(50% - 55px);
    &:focus {
      outline: none;
    }

    img {
      width: 40px;
      height: 40px;
    }

    svg {
      width: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  .forward-icon {
    top: 50%;
    left: 90%;
  }

  .backward-icon {
    top: 50%;
    left: 10%;
  }

  /*  .play-pause-btn {
    position: absolute;
    top: 50%;
    left: 50%;
  } */

  .volume-bar-wrapper {
    display: flex;
    // margin-bottom: 5px;
    justify-content: space-between;

    // @include mobile {
    //   display: none;
    // }
    &__range {
      display: flex;
      align-items: center;

      &:hover {
        input[type="range"] {
          width: 100px;
        }

        outline: none;

        input[type="range"]::-webkit-slider-thumb {
          width: 15px;
          // visibility: visible;
        }

        input[type="range"]::-moz-range-thumb {
          width: 15px;
          // visibility: visible;
        }
      }

      .mute-unmute-icon {
        width: 15px;
        height: 15px;
        cursor: pointer;
      }

      input[type="range"] {
        // width: 100px;
        width: 0px;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: scale-horizontal;
        background: transparent;
        padding: 5px 0;
        padding-right: 5px;

        transition: width 0.2s ease-in-out;
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
      }

      input[type="range"]:focus {
        outline: none;
      }

      input[type="range"]::-ms-track {
        width: 100%;
        cursor: pointer;

        background: transparent;
        border-color: transparent;
        color: transparent;
      }

      input[type="range"]::-webkit-slider-thumb {
        // visibility: hidden;

        -webkit-appearance: none;
        transition: width 0.1s ease-in-out;
        /* border: 1px solid #000000; */
        height: 15px;
        // width: 15px;
        width: 0px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
        margin-top: -6px;
        /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;*/
      }

      /* All the same stuff for Firefox */
      input[type="range"]::-moz-range-thumb {
        // visibility: hidden;

        -webkit-appearance: none;
        transition: width 0.1s ease-in-out;
        /* border: 1px solid #000000; */
        height: 15px;
        // width: 15px;
        width: 0px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
        margin-top: -6px;
        /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;*/
      }

      /* All the same stuff for IE */
      input[type="range"]::-ms-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
      }

      input[type="range"]::-webkit-slider-runnable-track {
        /* width: 100%; */
        height: 4px;
        cursor: pointer;
        /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
        background: #ffffff;
        border-radius: 1.3px;
        border: 0.2px solid #010101;
      }

      // input[type="range"]:focus::-webkit-slider-runnable-track {
      //   background: #367ebd;
      // }

      input[type="range"]::-moz-range-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        background: #ffffff;
        border-radius: 1.3px;
        border: 0.2px solid #010101;
      }

      input[type="range"]::-ms-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
      }

      input[type="range"]::-ms-fill-lower {
        background: #ffffff;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      }

      input[type="range"]:focus::-ms-fill-lower {
        background: #ffffff;
      }

      input[type="range"]::-ms-fill-upper {
        background: #ffffff;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      }

      input[type="range"]:focus::-ms-fill-upper {
        background: #ffffff;
      }
    }
  }

  progress {
    width: 100%;
  }

  progress[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    appearance: none;

    width: 100%;
    height: 4px;
    cursor: pointer;
  }

  progress[value]::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }

  progress[value]::-webkit-progress-value {
    background-color: var(--primary-color);
    -webkit-animation: animate-stripes 5s linear infinite;
    animation: animate-stripes 5s linear infinite;
  }

  /* progress[value]::-webkit-progress-value::before {
        content: "80%";
        position: absolute;
        right: 0;
        top: -125%;
      } */

  progress[value]::-webkit-progress-value::after {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 100%;
    right: 7px;
    top: 7px;
    background-color: white;
  }

  .video-timings {
    color: white;
    font-family: Roboto-Regular, Roboto, sans-serif;
    font-size: 14px;
    padding-top: 5px;
    text-align: right;
  }

  @-webkit-keyframes animate-stripes {
    100% {
      background-position: -100px 0px;
    }
  }

  @keyframes animate-stripes {
    100% {
      background-position: -100px 0px;
    }
  }
}

.forward-icon-wrapper {
  position: absolute;
  top: 0;
  height: calc(100% - 95px);
  // height: 100%;
  /* transform: translate(-50%, -50%); */
  display: flex;
  right: 0;
  width: 40%;

  button {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0 16px 0 16px;
    color: white;
    visibility: hidden;
    width: 100%;

    img {
      width: 25px;
      height: 25px;
      // float: right;
    }
  }
}

.backward-icon-wrapper {
  position: absolute;
  top: 0;
  height: calc(100% - 95px);
  // height: 100%;
  /* transform: translate(-50%, -50%); */
  display: flex;
  left: 0;
  width: 40%;

  button {
    background-color: transparent;
    outline: none;
    border: none;
    // display: flex;
    padding: 0 16px 0 16px;
    visibility: hidden;
    width: 100%;

    img {
      width: 25px;
      height: 25px;
    }
  }
}

// video progress bar
.audio-progress {
  height: 3px;
  width: 100%;
  background-color: #c0c0c0;
  position: relative;
}

.audio-progress .bar {
  height: 100%;
  background-color: #e95f74;
}

#audio-progress-handle {
  display: block;
  position: absolute;
  z-index: 1;
  margin-top: -6px;
  margin-left: -10px;
  width: 1rem;
  height: 1rem;
  border: 4px solid #d3d5df;
  border-top-color: #d3d5df;
  border-right-color: #d3d5df;
  transform: rotate(45deg);
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.draggable {
  float: left;
  position: absolute;
  margin: 0 10px 10px 0;
}

#draggable-point {
  top: 0 !important;
}

.audio-progress {
  cursor: pointer;
}
.volume-bar-wrapper__range input[type="range"] {
  cursor: pointer;
}
.video-progress-wrapper {
  .bar {
    background-color: var(--primary-color);
    position: absolute;
    // width: 20%;
    z-index: 0;
    cursor: pointer;
    height: 4px;
    border-radius: 5px;
    margin-top: 11px;
  }

  input[type="range"] {
    -webkit-appearance: none;
    margin: 12px/2 0;
    width: 100%;
  }

  input[type="range"] {
    // color: rgb(241, 8, 8);
    outline: none;
    cursor: pointer;
    border: initial;
    margin: 0px;
    border-radius: 5px;
    height: 1px;
    padding-top: 13px;
    padding-bottom: 13px;

    background-color: transparent !important;
    -webkit-appearance: none;
    // width: 80px;

    // transform: rotate(270deg);

    // position: relative;
    // left: -32px;
    // top: 28px;

    // border: 1px solid;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    cursor: pointer;
    height: 2px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    width: 100%;
    box-shadow: 0 0 0 #222, 0 0 0 lighten(#222, 5%);
    background: #eee;
    border-radius: 5px;
  }

  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0 0 0 #111, 0 0 0 lighten(#111, 5%);
    background: var(--primary-color);
    border: 0 solid #fff;
    border-radius: 12px;
    cursor: pointer;
    height: 16px;
    width: 16px;
    -webkit-appearance: none;
    // margin-top: ((0 * 2 + 10px)/2) - (12px/2);
    margin-top: -7px;
    outline: none;

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }
  }
}

.thumb {
  width: 16px;
  height: 16px;
  position: absolute;
  background-color: var(--primary-color);
  border-radius: 50%;
  top: 50%;
  transform: translateY(-4px);
  left: 14.593161275275362%;
}

.show-range {
  input[type="range"] {
    width: 100px !important;
  }

  outline: none;

  input[type="range"]::-webkit-slider-thumb {
    width: 15px !important;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  .volume-bar-wrapper__range {
    .mute-unmute-icon {
      position: absolute;
      right: 0;
    }
  }
}
.video-volume-bar {
  padding: 10px 0 !important;
}
