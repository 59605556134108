@import "../../../../../assets/scss/variables.scss";

.enterOtp {
  margin-top: 10px;
  padding: 5px 0;
  font-size: 16px;
  color: var(--secondary-color);
}

.error {
  font-size: 12px;
  color: $lp;
  text-align: left;
}

.resendOtp {
  color: var(--primary-color);
  font-size: 11px;
  text-align: right;
  padding: 3px 0;
  // margin-bottom: 1em;
}

.submitButton {
  background: $lb;
  color: #fff;
  outline: none;
  margin: 15px 0;
  font-size: 20px;
  padding: 12px;
  display: block;
  text-align: center;
  width: 100%;
  // box-shadow: 0 2px 24px 0 rgba(50, 197, 255, 0.73);
  border-radius: 50px;
  border-style: none;
  &.no-shadow {
    box-shadow: none !important;
  }
}

.submitButton:disabled {
  border: none;
  background-color: $lb;
}

.change-mobile {
  display: inline;
  font-size: 11px;
  color: var(--primary-color) !important;
}

.smallPara {
  font-size: 13px;
  color: var(--secondary-color);
  margin-top: 10px;
  margin-bottom: 15px;
  line-height: 18px;
}

.validateOTP {
  width: 100% !important;
  border-radius: 8px !important;
}

.loader2 {
  position: absolute;
  height: 24px !important;
  width: 24px !important;
  right: 20px;
  margin-top: 0 !important;
  top: 10px;
}
