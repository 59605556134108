@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";
.web-more-link {
  .MuiPaper-root {
    display: block;
    position: absolute;
    bottom: 9%;
    right: 25.6%;
    background: var(--background-color);
    color: #4b4b4b;
    // padding: 15px 20px;
    padding: 15px 0;

    border-radius: 3px;
    z-index: 2;
    font-size: 16px;
    width: 206px;
    margin: 0;
    @include mobile {
      left: 0;
      right: auto;
      width: 100%;
      bottom: 0;
    }
    .MuiDialogTitle-root {
      padding: 0 20px;
      padding-bottom: 10px;
      color: var(--text-color);
      .MuiTypography-root {
        font-size: inherit;
        line-height: initial;
      }
    }
    .MuiList-root {
      font-size: 14px;
      cursor: pointer;
      padding: 0;
      .MuiListItem-root {
        // padding: 2px 20px;
        padding: 8px 20px;
        display: flex;
        align-items: flex-start;
        // margin: 16px 0;
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          margin-right: 8px;
        }
        .link-text {
          p {
            margin: 0;
            font-size: 13px;
            color: var(--text-color);
          }
          span {
            font-size: 12px;
            color: var(--text-color);
          }
        }
      }
    }
  }
}

.contentOption {
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  color: var(--text-color) !important;
  background-color: var(--background-color-contrast);
  max-width: 250px;
  min-width: 200px;
  @include mobile {
    padding: 20px 20px 20px 10px !important;
    font-size: 16px !important;
  }
  .oval {
    margin-right: 0px;
    img {
      width: 21px;
      height: 21px;
      vertical-align: middle;
      margin-top: 5px;
    }
  }
  span {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  svg {
    width: 20px;
    margin-right: 9px;
    vertical-align: middle;
  }
  p {
    margin-bottom: 0;
    display: inline-block;
    margin: 0 !important;
  }
  .report {
    p {
      color: #ff3636 !important;
    }
  }
}
.bmActive {
  #Path {
    fill: #ff3636;
    stroke: #ff3636;
  }
}

.modal {
  .paper1 {
    min-height: 300px !important;
    max-width: 500px !important;
    border-radius: 15px;
    margin: 1rem;
    position: relative;
    min-width: 200px;
    justify-content: center;
    outline: none;
    &.sound-modal {
      height: auto !important;
      width: 500px;
      background-color: var(--background-color-contrast);
      @include mobile {
        width: 100%;
        max-width: 100%;
        margin: 0;
      }
      .closeBtn:before,
      .closeBtn:after {
        background: var(--text-color);
      }
      .p-50 {
        padding: 0 50px 30px 50px !important;
        &.maxHeight {
          max-height: 560px;
          max-width: 360px;
          height: 560px;
          overflow-y: auto;
          display: block;
          &::-webkit-scrollbar-thumb {
            border: none;
            background-color: none;
            border-radius: 10px;
            background-clip: padding-box;
          }
          &::-webkit-scrollbar {
            // width: 5px;
            display: none;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
        }
        .closeBtn {
          right: 0;
          top: 10px;
          &:hover {
            background: hsla(0, 0%, 9%, 0.2);
          }
        }
      }

      // .volume-bar-wrapper {
      //   display: block;
      //   width: 120px;
      //   justify-content: space-between;

      //   &__range {
      //     width: 200px;
      //     display: inline;
      //     align-items: center;

      //     &:hover {
      //       input[type="range"] {
      //         width: 95px;
      //         margin-right: 5px;
      //       }

      //       outline: none;

      //       input[type="range"]::-webkit-slider-thumb {
      //         width: 15px;
      //       }

      //       input[type="range"]::-moz-range-thumb {
      //         width: 15px;
      //       }
      //     }

      //     .mute-unmute-icon {
      //       width: 21px;
      //       height: 21px;
      //       cursor: pointer;
      //     }

      //     input[type="range"] {
      //       width: 95px;
      //       outline: none;
      //       margin-right: 5px;
      //       -webkit-appearance: none;
      //       -moz-appearance: scale-horizontal;
      //       background: transparent;
      //       padding: 5px 0;
      //       padding-right: 5px;
      //       transition: width 0.2s ease-in-out;
      //     }

      //     input[type="range"]::-webkit-slider-thumb {
      //       -webkit-appearance: none;
      //     }

      //     input[type="range"]:focus {
      //       outline: none;
      //     }

      //     input[type="range"]::-ms-track {
      //       width: 100%;
      //       cursor: pointer;

      //       background: transparent;
      //       border-color: transparent;
      //       color: transparent;
      //     }

      //     input[type="range"]::-webkit-slider-thumb {
      //       -webkit-appearance: none;
      //       transition: width 0.1s ease-in-out;
      //       height: 15px;
      //       width: 0px;
      //       border-radius: 50%;
      //       background: #7c7676;
      //       cursor: pointer;
      //       margin-top: -6px;
      //     }

      //     input[type="range"]::-moz-range-thumb {
      //       -webkit-appearance: none;
      //       transition: width 0.1s ease-in-out;
      //       height: 15px;
      //       width: 0px;
      //       border-radius: 50%;
      //       background: #ffffff;
      //       cursor: pointer;
      //       margin-top: -6px;
      //     }

      //     input[type="range"]::-ms-thumb {
      //       box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      //       border: 1px solid #000000;
      //       height: 36px;
      //       width: 16px;
      //       border-radius: 3px;
      //       background: #ffffff;
      //       cursor: pointer;
      //     }

      //     input[type="range"]::-webkit-slider-runnable-track {
      //       height: 4px;
      //       cursor: pointer;
      //       background: #6c7b8a;
      //       border-radius: 1.3px;
      //     }

      //     input[type="range"]::-moz-range-track {
      //       width: 100%;
      //       height: 8.4px;
      //       cursor: pointer;
      //       box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      //       background: #6c7b8a;
      //       border-radius: 1.3px;
      //     }

      //     input[type="range"]::-ms-track {
      //       width: 100%;
      //       height: 8.4px;
      //       cursor: pointer;
      //       background: transparent;
      //       border-color: transparent;
      //       border-width: 16px 0;
      //       color: transparent;
      //     }

      //     input[type="range"]::-ms-fill-lower {
      //       background: #6c7b8a;
      //       border-radius: 2.6px;
      //       box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      //     }

      //     input[type="range"]:focus::-ms-fill-lower {
      //       background: #6c7b8a;
      //     }

      //     input[type="range"]::-ms-fill-upper {
      //       background: #6c7b8a;
      //       border-radius: 2.6px;
      //       box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      //     }

      //     input[type="range"]:focus::-ms-fill-upper {
      //       background: #6c7b8a;
      //     }
      //   }
      // }
    }
  }
}
.sound-container {
  width: auto !important;
  position: relative;
  padding: 25px 40px;
  display: block;
  border-radius: 10px;
  color: var(--text-color);
  background-color: var(--background-color-contrast);
  height: auto !important;
  padding-bottom: 0;
  @include mobile {
    height: 100vh !important;
    top: 50px;
    padding: 15px 25px;
  }
}
.ambsound {
  // min-width: 280px;
  color: var(--text-color) !important;
  font-size: 14px;
  min-width: 100%;
  .volume {
    padding-right: 0;
  }
  .savebtn {
    margin: 0;
  }
  .error {
    text-align: center;
  }
  .progress-bar {
    padding-left: 0;
    padding-right: 0;
    .p-right {
      padding-right: 4px;
    }
    .middle {
      text-align: center;
    }
  }
  .MuiListItem-button {
    cursor: default;
    p {
      cursor: default;
    }
    progress {
      //float: left;
      color: #32c5ff;
      font-size: 10px;
      width: 100%;
      height: 18px;
      border: none;
      // border-radius: 22px;
      background: var(--text-color);
      vertical-align: middle;
    }
    progress::-moz-progress-bar {
      color: #32c5ff;
      background: var(--text-color);
      // border-radius: 5px;
    }

    progress[value]::-webkit-progress-bar {
      background-color: var(--sidebar-color);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
      // border-radius: 20px;
    }

    progress[value]::-webkit-progress-value {
      background-color: #32c5ff;
      // border-radius: 20px;
    }
  }
  .submitButton {
    // height: auto !important;
    // padding: 8px 8px 9px;
    max-width: 150px !important;
    &:disabled {
      background-color: #6c7b8a;
      cursor: default;
    }
    @include mobile {
      max-width: 100% !important;
    }
  }
  .volume-bar-wrapper__range {
    width: 100%;
    text-align: right;
    img {
      cursor: pointer;
    }
  }
  .p0 {
    padding: 0;
    h3 {
      text-align: right;
      width: 100%;
      font-size: 16px;
      font-weight: normal;
      input {
        width: 18px;
        height: 18px;
      }
    }
  }
  .amblist {
    max-height: 250px;
    overflow-y: scroll;
    @include mobile {
      max-height: calc(100vh - 260px);
    }
    .MuiIconButton-label svg.MuiSvgIcon-root {
      transform: none !important;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .m-0 {
      padding: 5px 0;
    }
  }
  .volume-bar-wrapper__range img {
    width: 20px;
    // top: 17px;
    position: relative;
    margin-left: 7px;
    padding: 7px 0 !important;
  }
  .playicon {
    min-width: auto;
    text-align: center;
    cursor: pointer;
    .play-icon {
      background: #32c5ff;
      border-radius: 50%;
      width: 32px !important;
      height: 32px !important;
    }
  }
  .ambrow {
    padding: 0;
    .sound-name {
      padding-left: 5px;
    }
    .right0 {
      right: 0;
      input {
        width: 18px;
        height: 18px;
      }
    }
  }
}
.sound-checkbox {
  position: relative;

  input[type="checkbox"] {
    cursor: pointer;
    visibility: hidden;
    &:checked {
      & + label {
        cursor: pointer;
        background-color: #32c5ff;
        border-color: #32c5ff;
        &:after {
          opacity: 1;
        }
      }
    }
  }

  label {
    background-color: #fff;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    right: 0;
    position: absolute;
    top: 3px;
    width: 20px;
    &:after {
      display: none;
      cursor: pointer;
      border: 2px solid #32c5ff;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 7px;
      opacity: 0;
      position: absolute;
      // top: 8px;
      top: 2px;
      transform: rotate(-45deg);
      width: 12px;
    }
  }
}
