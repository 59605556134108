@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.svg-icon.fav-left .primary {
  fill: var(--input-bg);
}
.svg-icon.fav-left .secondary {
  fill: var(--input-text);
}

.svg-icon.fav-right .primary {
  fill: var(--input-bg);
}
.svg-icon.fav-right .secondary {
  fill: var(--input-text);
}
