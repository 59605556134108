@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixin.scss";

.dialog-modal {
  &.report-content-modal {
    .MuiPaper-root {
      width: 320px;
      padding: 30px 12px 20px;
      height: 375px;
      border-radius: 15px;
      .MuiDialogTitle-root {
        margin-bottom: 15px;
        text-align: center;
        .MuiTypography-root {
          margin-bottom: 5px;
        }
      }
      .modal-body {
        max-height: 240px;
        overflow-y: auto;
        padding: 0 10px;
        .redio-btn {
          line-height: 40px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: var(--text-color);
          -webkit-tap-highlight-color: transparent;
          &:hover {
            cursor: pointer;
            color: #6394b4;
          }
          input[type="radio"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            display: inline-block;
            width: 15px;
            height: 15px;
            padding: 3px;
            background-clip: content-box;
            // border: 1px solid #5393cf;
            border: 1px solid #505050;
            background-color: #fff;
            border-radius: 50%;
            outline: 0;
            margin-right: 10px;
            margin-top: 0;
            &:checked {
              background-color: var(--primary-color);
              border: 1px solid var(--primary-color);
            }
          }
          label {
            cursor: pointer;
            color: var(--text-color);
          }
        }
      }
      .modal-footer {
        ul {
          display: flex;
          justify-content: flex-end;
          li {
            margin: 0 15px;
            &:last-child {
              margin-right: 0;
            }
            button {
              &:disabled {
                color: var(--menu-btn-color);
              }
            }
            .error {
              color: $lp;
              font-size: 11px;
              margin-top: -5px;
            }
          }
        }
      }
    }
  }
}
.dkt {
  .dialog-modal.report-content-modal .MuiPaper-root .modal-body .redio-btn input[type="radio"] {
    background-color: #333;
    border: 1px solid #aaa !important;
  }
  .dialog-modal.report-content-modal .MuiPaper-root .modal-body .redio-btn input[type="radio"]:checked {
    background-color: #aaa;
  }
}

.account-report-modal {
  background-color: var(--background-color-contrast);
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 16px 16px 0 rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
  color: var(--text-color);
  .report-red {
    // color:  #6c7b8a !important;
    color: #ff3636 !important;
    cursor: pointer;
    svg {
      color: #ff3636;
    }
  }
  .MuiSvgIcon-root {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 10px;
  }
  label {
    margin-left: 0;
    display: block;
  }
  li {
    label {
      padding: 5px 10px;
      display: block;
    }
  }
  input {
    border: none;
    padding: 5px;
    margin-top: 10px;
    color: var(--text-color);
    border-radius: 4px;
    background-color: #f4f4f4;
    outline: none;
    -webkit-appearance: none;

    @include mobile {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  button {
    width: 50%;
  }
}
