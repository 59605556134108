@import "../../../../../assets/scss/mixin.scss";

.action-btn {
  padding: 0 0 5px;
  width: 100%;
  //height: 30%;
  //overflow-y: auto;
  // white-space: nowrap;
  @include mobile {
    padding: 0 0 3px;
  }
  .menu-wrapper--inner {
    margin-left: -50px;
    white-space: nowrap;
    @include mobile {
      margin-left: 0;
    }
    .menu-item-wrapper {
      padding-right: 5px;
      outline: none;
    }
  }
  .scroll-menu-arrow {
    @include mobile {
      display: none;
    }
    .svg-icon {
      box-shadow: 1px 1px 3px 0px rgba(128, 128, 128, 0.35);
      border-radius: 50%;
      margin-left: 2px;
      cursor: pointer;
      &.fav-right {
        margin-left: 0;
        margin-right: 2px;
      }
    }
  }
}
.menu-item {
  font-size: 13px;
  text-decoration: none;
  padding: 5px 20px 7px;
  // margin-right: 15px;
  letter-spacing: 0.3px;
  background: #f3f3f3;
  border: none;
  color: #fff;
  outline: none;
  border-radius: 4px;
  -webkit-tap-highlight-color: transparent;
  @include mobile-and-gplands {
    border-radius: 10px;
    box-shadow: 1px 1px 2px #ddd;
  }
}
.MuiTouchRipple-root {
  outline: 0;
}
.spl-btn.surprise {
  padding: 4px 8px !important;
}
.spl-btn.active {
  float: left;
  width: 200px;
}
.spl-btn {
  width: 200px;
  float: left;
   @include mobile {
      width: auto;
  float: left;
    }
}

.MuiButtonBase-cat {
  float: left;
  width: 200px;
}
.sliderGenre {
  .spl-btn {
    // width: 180px;
    width: 136px;
    padding-left: 5px !important;
    text-align: left;
    background-color: transparent;
    color: var(--sidebar-color);
  }
  .spl-btn .active {
    width: 180px;
  }
  .spl-btn.active-sidebar-cat {
    background-color: transparent;
    color: var(--sidebar-active-color);
  }
  .checked-icon {
    margin-left: 28px;
  }
}
