@import "../../../assets/scss/variables.scss";

.MuiBottomNavigation-root.footer-navigation {
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 2;
  height: 50px;
  background: var(--background-color-contrast);
  left: 0;
  bottom: -1px;
  padding: 0 10px;
  align-items: center;
  // border-top: 1px solid #f7f7f7;
  .MuiButtonBase-root {
    padding: 0;
    font-size: 22px;
    text-align: center;
    line-height: 28px;
    position: relative;
    // max-width: 60px;
    min-width: auto;
    text-align: center;
    width: 100%;
    .MuiBottomNavigationAction-wrapper {
      width: auto;
      .MuiBottomNavigationAction-label {
        font-size: 10px;
        letter-spacing: 0.3px;
        color: #707070;
        opacity: 1;
        line-height: 1;
        margin-top: 2px;
      }
    }
    &.active {
      .MuiBottomNavigationAction-label {
        color: $lp;
      }
      .home-m {
        #Path {
          fill: var(--sidebar-icon-fill-color) !important;
          stroke: var(--sidebar-icon-fill-color) !important;
        }
      }
    }
  }
}
.dkt {
  .MuiBottomNavigation-root {
    &.footer-navigation {
      .MuiButtonBase-root {
        &.active {
          .MuiBottomNavigationAction-label {
            color: #eee;
          }
          .home-m {
            #Path {
              fill: #fff !important;
              stroke: #fff !important;
            }
          }
        }
      }
    }
  }
}
.mobileUpload {
  background-color: #32c5ff !important;
  border-radius: 50px !important;
  height: 53px;
  // top: -15px;
  top: -23px;
  max-width: 53px !important;
  box-shadow: 0 -3px 24px 0 rgba(50, 197, 255, 0.49);
  svg {
    fill: #fff;
    width: 1em !important;
    height: 1em !important;
    // margin: 2px 0 0 2px;
  }
}
.discover-m {
  transform: scale(1.35);
}
.home-m {
  #Path {
    stroke: var(--sidebar-icon-fill-color) !important;
    stroke-width: 0.025em;
  }
}
.dkt {
  .discover-m {
    #Shape {
      fill: #fff !important;
    }
  }
  .home-m {
    #Path {
      stroke: #fff !important;
      fill: none;
    }
  }
}
