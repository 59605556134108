@font-face {
  font-family: "Lato";
  font-weight: 400;
  src: url("../../assets/fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeue";
  font-weight: 400;
  src: url("../../assets/fonts/HelveticaNeueLt.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Bold";
  font-weight: 900;
  src: url("../../assets/fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Acme";
  font-size: 16px;
  src: url("../../assets/fonts/Acme-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Arapey";
  font-size: 16px;
  src: url("../../assets/fonts/Arapey-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "CormorantGaramond";
  font-size: 16px;
  src: url("../../assets/fonts/CormorantGaramond-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "EbGaramond";
  font-size: 16px;
  src: url("../../assets/fonts/EBGaramond-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "JosefinSans";
  font-size: 16px;
  src: url("../../assets/fonts/JosefinSans-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "KaushanScript";
  font-size: 16px;
  src: url("../../assets/fonts/KaushanScript-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "LibreBaskerville";
  font-size: 16px;
  src: url("../../assets/fonts/LibreBaskerville-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Lobster";
  font-size: 16px;
  src: url("../../assets/fonts/Lobster-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Lora";
  font-size: 16px;
  src: url("../../assets/fonts/Lora-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Lusitana";
  font-size: 16px;
  src: url("../../assets/fonts/Lusitana-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "NotoSerif";
  font-size: 16px;
  src: url("../../assets/fonts/NotoSerif-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "PTSerif";
  font-size: 16px;
  src: url("../../assets/fonts/PTSerif-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Quicksand";
  font-size: 16px;
  src: url("../../assets/fonts/Quicksand-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Satisfy";
  font-size: 16px;
  src: url("../../assets/fonts/Satisfy-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SawarabiMincho";
  font-size: 16px;
  src: url("../../assets/fonts/SawarabiMincho-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SpecialElite";
  font-size: 16px;
  src: url("../../assets/fonts/SpecialElite-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Volllorn";
  font-size: 16px;
  src: url("../../assets/fonts/Vollkorn-Regular.ttf") format("truetype");
  font-display: swap;
}
