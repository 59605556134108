.lnk .grd-frm {
  padding: 10px 10px 5px !important;
}
.pv {
  margin-top: 5px;
}
.desc {
  max-height: 150px;
  overflow-y: scroll;
}
.title {
  font-family: "Lato-Bold" !important;
}

.MuiSwitch-root {
  right: -20px;
  transform: translateY(-50%);
  width: 80px !important;
  top: 5px;
  .MuiSwitch-track {
    opacity: 1;
    background-color: var(--switch-track-background);
    width: 48px;
    height: 25px;
    border-radius: 15px;
  }
  .MuiSwitch-thumb {
    height: 18px;
    width: 18px;
    margin-left: 8px;
    margin-top: 6px;
  }
}
.matureSwitch {
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background: var(--danger-color) !important;
    opacity: 1;
  }
  .Mui-checked {
    color: #fff !important;
    fill: #fff !important;
  }
}
