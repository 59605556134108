@import "../../../assets/scss/mixin.scss";

.modal {
  &.modal-backdrop {
    .MuiBackdrop-root {
      // background-color: transparent;
    }
  }
  .paper {
    &.notification-modal {
      //position: fixed;
      width: 300px;
      right: 0px;
      max-height: 80vh;
      //top: 44px;
      padding: 10px;
      min-height: auto;
      overflow-y: auto;

      margin: 0;
      background: var(--background-color-contrast);
      border-radius: 4px;
      box-shadow: 0 16px 16px 0 rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
      .notifbtn {
        width: 100%;
        display: block;
      }
      .notifi-header {
        color: var(--text-color);
        background: var(--background-color-contrast);
        padding: 15px;
        display: flex;
        justify-content: space-between;
        margin: 0 -15px;
        position: fixed;
        top: 44px;
        width: 400px;
        z-index: 11;
      }
      .notif-msg {
        margin: 1.5em 0.5em;
        img {
          // width: 20%;
          // height: 20%;
          // margin-bottom: 10px;
          // margin-right: 5px;
          // vertical-align: middle;
          // border-radius: 50%;
        }
        h5 {
          font-size: 17px;
          color: var(--text-color);
          margin: 10px 0;
          font-weight: normal;
        }
        h6 {
          margin: 10px 0;
          color: #707070;
          font-size: 13px;
          font-weight: normal;

        }
      }
      /*notifications*/
      .notification-tile {
        text-align: left;
      }
      .notification-scroll {
        display: flex;
        margin: 13.5px 0;
        text-decoration: none;
        color: var(--main-color);
        font-size: 13px;
        cursor: pointer;
        justify-content: space-between;
        flex-wrap: wrap;
        &:hover {
          color: #6394b4;
        }
        .time {
          font-size: 10px;
        }
      }
      .notification-image {
        flex-basis: 1 0 100%;
        .img-container {
          width: 95% !important;
          height: 130px;
          margin: 0.5em auto;
          border-radius: 4px;
          clear: both;
          position: relative;
          img {
            object-fit: cover;
            height: auto !important;
            max-height: 100%;
            border-radius: 4px;
          }
        }
      }
      .notificationSlider {
        display: flex;
        margin: 6.75px 0;
        white-space: nowrap;
        width: 95%;
        overflow: auto;
        padding: 0;
        margin: 0.5em auto;
        li {
          margin-right: 10px;
          margin-bottom: 4px;
          img {
            width: 90px !important;
            height: 130px !important;
            object-fit: cover;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
.notification-list {
  padding: 10px;
  display: block;
  width: 100%;
  .top-label,
  small {
    color: var(--notification-color) !important;
    font-size: 12px;
    margin-left: 10px;
  }
  small {
    margin-left: 16px;
  }
}
.notify-play {
  position: absolute;
  z-index: 101;
  background-color: #000;
  border-radius: 50%;
  left: 45%;
  top: 50%;
}
// .notifi-header {
//   color: #505050;
//   background: #efefef;
//   padding: 15px;
//   display: flex;
//   justify-content: space-between;
//   margin: 0 -15px;
//   position: fixed;
//   top: 44px;
//   width: 400px;
//   z-index: 11;
// }
// .notif-msg {
//   padding-top: 8px;
//   text-align: center;
//   height: 90vh;
//   @include mobile {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     // height: calc(100vh - 100px);
//     height: auto;
//     align-items: center;
//   }
//   .content {
//     @include mobile {
//       width: 100%;
//       // position: absolute;

//       position: fixed;
//       top: 50%;
//       transform: translate(-50%, -50%);
//       left: 50%;
//     }
//   }
//   img {
//     width: 20%;
//     height: 20%;
//     margin-bottom: 10px;
//     margin-right: 5px;
//     vertical-align: middle;
//     border-radius: 50%;
//     @include mobile {
//       width: 30%;
//       margin: 0;
//       margin-bottom: 30px;
//       max-width: 102px;
//     }
//   }
//   h5 {
//     font-size: 17px;
//     color: #aaa;
//     margin: 10px 0;
//   }
//   h6 {
//     margin: 10px 0;
//     color: #707070;
//     font-size: 13px;
//     @include mobile {
//       margin-top: 0;
//     }
//   }
// }
// /*notifications*/
// .notification-tile,
// .notification-scroll {
//   display: flex;
//   margin: 13.5px 0;
//   text-decoration: none;
//   color: var(--main-color);
//   font-size: 13px;
//   cursor: pointer;
//   justify-content: flex-start;
//   flex-wrap: wrap;
//   &:hover {
//     color: #6394b4;
//   }
//   @include mobile {
//     padding: 0 10px;
//   }
//   .time {
//     font-size: 10px;
//   }
// }
// .notification-image {
//   flex-basis: 1 0 100%;
//   .img-container {
//     width: 95% !important;
//     height: 130px;
//     margin: 0.5em auto;
//     border-radius: 4px;
//     clear: both;
//     position: relative;
//     img {
//       object-fit: cover;
//       height: auto !important;
//       max-height: 100%;
//       border-radius: 4px;
//     }
//   }
// }
// .notificationSlider {
//   display: flex;
//   margin: 6.75px 0;
//   white-space: nowrap;
//   width: 95%;
//   overflow: auto;
//   padding: 0;
//   margin: 0.5em auto;
//   li {
//     margin-right: 10px;
//     margin-bottom: 4px;
//     img {
//       width: 90px !important;
//       height: 130px !important;
//       object-fit: cover;
//       border-radius: 4px;
//     }
//   }
// }
// .notifbtn {
//   width: 100%;
//   display: block;
// }
