@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";
.modal {
  .paper {
    &.feedback-modal {
      width: 500px;
      min-height: auto;
      .modalHeader {
        margin: 0;
        .modalTitle {
          color: var(--text-color);
        }
      }
      .modalBody {
        .description {
          color: #606060;
          margin-top: 10px;
          line-height: 18px;
          position: relative;
          margin-bottom: 10px;
          width: 100%;
          input,
          textarea {
            padding: 10px;
            border-radius: 4px;
            font-size: 13px;
            display: block;
            width: 100%;
            color: var(--input-text);
            background: var(--input-bg);
            border: 1px solid transparent;
            outline: 0;
            &::-webkit-input-placeholder {
              font-size: 13px;
              color: var(--placeholder);
            }
          }
          textarea {
            resize: none;
          }
        }
      }
      .modalFooter {
        button {
          margin: 10px 0;
          width: 200px;
        }
        .submit:disabled {
          border-color: #f56e9d;
          background-color: #f56e9d;
          cursor: default;
        }
      }
    }
  }
}
