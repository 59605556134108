@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";
.share-icon {
  font-size: 12;
  color: "#707070";
  padding: "0 10px 6px";
}
.cardFooterli {
  color: #6b7b8b !important;
  padding: 2px 10px 0px;
  font-size: 12px;
  @include mobile {
    padding: 4px 10px 0;
  }
  > button {
    span {
      // font-size: 12px;
      font-family: lato;
    }
  }
  .more-option-content {
    @include mobile {
      margin-right: 8px;
    }
  }
}
.cardFooterli.account-page-only {
  margin-left: 0;
  padding-left: 0;
  .svg-icon.more-option-content .primary {
    fill: var(--main-card-color);
  }
}
.delete-post-modal {
  .MuiPaper-root {
    background-color: var(--background-color-contrast);
    color: var(--grey-white);
    .MuiList-padding {
      padding: 0;
    }
  }
}
// .like-inactive {
//   #Path {
//     fill: #f9f9f9 !important;
//   }
// }
.like-active {
  #Path {
    stroke: #ff2c2c !important;
    fill: #ff2c2c !important;
  }
}
// .footer-detail {
//   padding-top: 7px !important;
//   img {
//     width: 24px !important;
//     height: 24px !important;
//     margin-left: 5px !important;
//     cursor: pointer;
//   }
//   .top-label {
//     font-size: 12px !important;
//     padding-top: 6px !important;
//     max-width: 100px;
//     overflow: hidden;
//     white-space: nowrap;
//     color: #6c7b8a !important;
//     cursor: pointer;
//     @include mobile {
//       font-size: 14px !important;
//       padding-top: 4px !important;
//     }
//   }
// }

.footbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  .username {
    display: flex !important;
    align-items: center !important;
    justify-content: left !important;
    cursor: pointer;
    color: #6b7b8b !important;
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      vertical-align: middle;
    }
    label {
      font-size: 13px !important;
      padding-left: 5px;
      cursor: pointer;
      margin: 0 !important;
    }
  }
}
.dkt .footbar .username {
  color: #6b7b8b !important;
}
