@import "../../assets/scss/mixin.scss";

.followersModalMobile {
  padding: 10px;
  .modalHeader {
    border-bottom: 1px solid #eee;
  }
  .modalBody {
    max-height: 100vh;
    overflow-y: auto;
    position: relative;
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    h6 {
      width: 100%;
      margin: 0;
      font-size: 1.25em;
    }
    .follow-list {
      display: block;
      margin-top: 20px;
      width: 100%;
      overflow-y: auto;
      .follow-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .follow-name {
          max-width: 80%;
          img {
            vertical-align: middle;
            border-radius: 50%;
            margin-right: 10px;
            width: 30px;
            height: 30px;
          }
        }
        .follow-btn {
          .f-btn {
            background: var(--primary-color);
            color: #fff;
            text-align: center;
            cursor: pointer;
            border-radius: 50px;
            width: 100px;
            font-size: 0.8em;
            &:hover {
              background: var(--primary-color) !important;
            }
            &.f-btn-dis {
              color: #a6a6a6 !important;
              background: #e4e4e4 !important;
              &:hover {
                color: #a6a6a6 !important;
                background: #e4e4e4 !important;
              }
            }
          }
        }
      }
    }
  }
}
