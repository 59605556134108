@import "../../assets/scss/mixin.scss";

.upload-textarea {
  width: 100%;
  height: 45px;
  margin-bottom: 10px;
  padding: 5px 9px;
  border-radius: 8px;
  border: none;
  font-size: 13px !important;
  resize: none;
  background-color: var(--background-misc) !important;
  color: var(--text-color) !important;

  @include mobile {
    background-color: var(--background-color-contrast) !important;
  }
}

.quill {
  background-color: var(--background-misc) !important;
  color: var(--text-color) !important;
  font-size: 14px !important;
  border-radius: 8px;

  @include mobile {
    background-color: var(--background-color-contrast) !important;
  }

  .ql-toolbar {
    border: none;
  }

  .ql-container {
    border: none;
  }

  .ql-editor {
    min-height: 180px;
  }
}

.MuiButton-contained:hover {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  box-shadow: none !important;
}

.modalTitle {
  font-size: 46px;
}

.upload-sec {
  background-color: var(--background-misc) !important;
  color: var(--text-color) !important;
}

.pref-btn.txt-lft {
  text-align: left !important;
  padding-bottom: 30px;
  margin-left: 8px;

  &.pb-5 {
    padding-bottom: 5px !important;
  }

  .spl-btn {
    background-color: var(--background-color);
    border-radius: 4px;
    color: var(--search-filter-bar-text-color);
    box-shadow: none;
    margin: 0 2px;
    width: calc((100% - 40px) / 4);

    &:hover {
      background-color: var(--background-color) !important;
      box-shadow: none;
    }

    &.active {
      background-color: var(--search-filter-bar-select-item-background-color);
      color: var(--search-filter-bar-select-item-text-color) !important;

      span {
        color: var(--search-filter-bar-select-item-text-color) !important;
      }

      &:hover {
        background-color: var(--search-filter-bar-select-item-background-color) !important;
        color: var(--search-filter-bar-select-item-text-color) !important;
      }
    }

    span {
      font-size: 14px;
      color: var(--text-color);
    }
  }
}

//mobile
.upload-btn {
  text-align: center;

  @include desktop {
    text-align: left;
  }

  svg {
    font-size: 2.5em;
    fill: #32c5ff;
  }

  .spl-btn {
    @include desktop {
      max-width: 8vw;
      max-height: 8vw;
      background-color: var(--background-color) !important;

      &:hover {
        background-color: var(--background-color) !important;
      }
    }

    height: calc(100vw / 5);
    width: calc(100vw / 5);
    margin: 5px !important;
    background-color: var(--background-color-contrast) !important;
    color: var(--text-color);
    padding: 0;

    &.img {
      svg {
        background-color: rgba($color: #ff0080, $alpha: 0.3);
      }

      &.active {
        background-color: #ff0080 !important;

        label {
          color: #fff;
        }

        svg {
          background-color: rgba($color: #000, $alpha: 0.1);

          #Add-images---multiple {
            fill: #fff;
          }
        }
      }
    }

    &.vid {
      svg {
        background-color: rgba($color: #f8860e, $alpha: 0.3);
      }

      &.active {
        background-color: #f8860e !important;

        label {
          color: #fff;
        }

        svg {
          background-color: rgba($color: #000, $alpha: 0.1);

          #Add-images---multiple {
            fill: #fff;
          }
        }
      }
    }

    &.stry {
      svg {
        background-color: rgba($color: #9ca87a, $alpha: 0.3);
      }

      &.active {
        background-color: #9ca87a !important;

        label {
          color: #fff;
        }

        svg {
          background-color: rgba($color: #000, $alpha: 0.1);

          #Add-images---multiple {
            fill: #fff;
          }
        }
      }
    }

    &.lnk {
      svg {
        background-color: rgba($color: #32c5ff, $alpha: 0.3);
      }

      &.active {
        background-color: #32c5ff !important;

        label {
          color: #fff;
        }

        svg {
          background-color: rgba($color: #000, $alpha: 0.1);

          #Add-images---multiple {
            fill: #fff;
          }
        }
      }
    }

    svg {
      padding: 7px;
      display: block;
      position: absolute;
      margin-top: -10px;
      width: 30px;
      height: 30px;
    }

    label {
      width: 100%;
      display: block;
      margin-left: 0;
      margin-top: 40px;
      cursor: pointer;
    }
  }
}

.progressbar {
  margin: 10px;

  .bar {
    display: block;
    width: 100%;
    margin: 5px 0;
    > svg {
      display: none !important;
    }
    .MuiLinearProgress-root,
    span,
    .close {
      display: inline-block;
      vertical-align: middle;
    }

    .MuiLinearProgress-root {
      width: 84%;

      @include desktop {
        width: 90%;
      }
    }

    span {
      font-size: 0.8em;
      margin: 0 5px;
    }

    .close {
      font-size: 1em;
      background-color: #e6e6e6;
    }
  }
}

.submitButton {
  margin: 0 auto;
  max-width: 300px;

  @include mobile {
    max-width: 100%;
  }
}

.video-pb {
  &.MuiLinearProgress-colorPrimary {
    background-color: rgba(248, 134, 14, 0.3) !important;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #f8860e !important;
  }
}

.image-pb {
  &.MuiLinearProgress-colorPrimary {
    background-color: rgba(255, 0, 128, 0.3) !important;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #ff0080 !important;
  }
}

.link-pb {
  &.MuiLinearProgress-colorPrimary {
    background-color: rgba(50, 197, 255, 0.3) !important;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #32c5ff !important;
  }
}

.story-pb {
  &.MuiLinearProgress-colorPrimary {
    background-color: rgba(156, 168, 122, 0.3) !important;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #9ca87a !important;
  }
}
.dkt .quill .ql-snow {
  svg .ql-fill {
    fill: #6c7b8a;
  }
  .ql-picker-label {
    color: #6c7b8a;
    svg .ql-stroke {
      stroke: #6c7b8a;
    }
  }
  svg .ql-stroke {
    stroke: #6c7b8a;
  }
}
