@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.NavItem .svg-icon.discover-web .primary {
  fill: var(--main-color);
}
.NavItem .svg-icon.discover-web .secondary {
  fill: none;
  stroke: var(--main-color);
  stroke-width: 0.8325;
  stroke-miterlimit: 10;
}

// .NavItem.NavItemDiscover:hover .svg-icon.discover-web .primary {
//   fill: #6394b4;
// }

.NavItem.NavItemDiscover:hover .svg-icon.discover-web .secondary {
  fill: none;
  // stroke: #6394b4;
  stroke-width: 0.8325;
  stroke-miterlimit: 10;
}

.NavItem.NavItemDiscover.active .svg-icon.discover-web .primary {
  fill: #fff;
}

.drawerClose .sidebarStyle .NavItem.NavItemDiscover.active .svg-icon.discover-web .primary {
  fill: var(--sidemenu-toggle-active);
}

.drawerClose .sidebarStyle .NavItem.NavItemDiscover.active .svg-icon.discover-web .secondary {
  stroke: var(--sidemenu-toggle-active);
}

.NavItem.NavItemDiscover.active .svg-icon.discover-web .secondary {
  fill: none;
  stroke: #fff;
  stroke-width: 0.8325;
  stroke-miterlimit: 10;
}

.footer-navigation .svg-icon.discover-web .primary {
  fill: #707070;
}

.footer-navigation .svg-icon.discover-web .secondary {
  fill: none;
  stroke: #707070;
  stroke-width: 0.8325;
  stroke-miterlimit: 10;
}

.footer-navigation .MuiButtonBase-root.active .svg-icon.discover-web .primary {
  fill: $lp;
}
.footer-navigation .MuiButtonBase-root.active .svg-icon.discover-web .secondary {
  fill: none;
  stroke: $lp;
  stroke-width: 0.8325;
  stroke-miterlimit: 10;
}

.svg-icon.discover-m .primary {
  // fill: var(--svg-sidebar-color);

  fill: $iw7;
  display: block;
}

.svg-icon.discover-m .secondary {
  fill: none;
  // stroke: var(--svg-sidebar-color);
  stroke: $iw7;

  stroke-width: 4.4657;
  stroke-miterlimit: 10;
  display: block;
}

.active .svg-icon.discover-m .primary {
  display: none;
}

.active .svg-icon.discover-m .secondary {
  display: none;
}
.svg-icon.discover-m .fill-primary {
  display: none;
}

.active .svg-icon.discover-m .fill-primary {
  fill: $lp;
  display: block;
}
.dkt .footer-navigation .MuiButtonBase-root.active .svg-icon.discover-m .fill-primary {
  fill: #eee;
}

.dkt .footer-navigation .MuiButtonBase-root.active .svg-icon.discover-m .fill-primary {
  stroke: #eee;
}
