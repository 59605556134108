@import "../../../assets/scss/mixin.scss";

.search {
  position: "relative";
  margin-left: 0;
  width: "100%";
}
.search-field {
  // background-image: url("../../../assets/images/search-icon-w.svg");
  background-color: var(--background-misc);
  border-radius: 8px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  .MuiSvgIcon-root {
    width: 0.85em;
    height: 0.85em;
  }
  @include mobile {
    background-color: #fff !important;
    padding-left: 0 !important;
    margin-right: 5px !important;
    .MuiSvgIcon-root {
      width: 0.8em !important;
      height: 0.8em !important;
    }
    .MuiSvgIcon-fontSizeSmall {
      @import "../../../assets/scss/mixin.scss";

      .search {
        position: "relative";
        margin-left: 0;
        width: "100%";
      }
      .search-field {
        // background-image: url("../../../assets/images/search-icon-w.svg");
        background-color: var(--background-misc);
        border-radius: 8px !important;
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        @include mobile {
          background-image: none;
          padding-left: 0 !important;
          background-color: var(--background-misc-mobile);
          margin-right: 5px !important;
          .MuiSvgIcon-fontSizeSmall {
            font-size: inherit;
          }
        }
        .MuiAutocomplete-root {
          width: 98% !important;
        }
        .MuiAutocomplete-root {
          display: inline-block;
          width: 98%;
        }
        > .MuiButtonBase-root {
          top: 3px;
          padding: 4px 2px 2px 4px;
        }

        .cross {
          position: relative;
          width: 12px;
          cursor: pointer;
          @include mobile {
            width: 14px;
            top: 0;
          }
        }
      }

      .MuiAutocomplete-popper {
        .MuiPaper-root {
          background-color: var(--background-color-contrast);
          color: var(--grey-white);
        }
      }

      .dkt {
        .search-field {
          @include mobile {
            background-image: none;
          }
          svg {
            fill: #aaa;
          }
        }
      }
      font-size: inherit;
    }
  }
  // .MuiSvgIcon-root {
  //    display: none;
  // }
  .MuiAutocomplete-root {
    display: inline-block;
    width: 98%;
  }
  > .MuiButtonBase-root {
    top: 3px;
    padding: 4px 2px 2px 4px;
  }
  .cross {
    position: relative;
    width: 12px;
    cursor: pointer;
    @include mobile {
      width: 14px;
      top: 0;
    }
  }
}

.MuiAutocomplete-popper {
  .MuiPaper-root {
    background-color: var(--background-color-contrast);
    color: var(--grey-white);
  }
}

.dkt {
  .search-field {
    @include mobile {
      background-color: #282b34 !important;
      background-image: none;
    }
    svg {
      fill: #aaa;
    }
  }
}

.searchbar {
  @include mobile {
    .MuiInputBase-root {
      background-color: var(--background-misc-mobile) !important;
      border-radius: 8px;
    }
  }
}

header .MuiToolbarRegular .search-field input {
  font-size: 16px !important;
}
