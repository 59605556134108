@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variables.scss";
.tymoff-account {
  position: relative;
  display: block;
  border-radius: 10px;
  padding: 0 !important;
  color: var(--text-color);
  background-color: var(--background-color-contrast);
  height: auto !important;
  padding-bottom: 30px;
  .p-50 {
    // padding: 40px 50px 30px 50px;
    padding: 60px 70px 50px 70px;
    @include mobile {
      padding: 35px;
    }
  }
  .back-btn {
    &.btn2 {
      background-color: #000;
      position: absolute;
      top: 25px;
      &::after,
      &::before {
        background-color: #000;
      }
      @include mobile {
        // left: 10px;
        // width: 40px;
      }
    }
  }
  .closeBtn {
    right: 0;
    top: 10px;
    &:hover {
      background: hsla(0, 0%, 100%, 0.2);
    }
  }
  .modalTitle {
    margin-bottom: 30px !important;
    color: var(--text-color);
    @include mobile {
      display: none;
    }
  }
  .orText {
    border-left: 1px solid #f1f1f1;
    height: 35%;
    margin-top: 72px;
    @include mobile {
      display: none;
    }

    div {
      &:last-child {
        p a {
          text-decoration: none;
          color: var(--text-color);
        }
      }
    }
  }
  .MuiGrid-container {
    > .MuiGrid-item {
      input,
      input:focus {
        box-sizing: border-box;
        outline: none;
        padding: 11px;
        border-radius: 8px;
        border: 1px solid var(--background-color);
        font-size: 12px !important;
        resize: none;
        color: var(--text-color);
        background: var(--input-bg);
        border-color: transparent;
        width: 100%;
        &::-webkit-input-placeholder {
          color: var(--placeholder);
        }
      }
    }
  }
  .gray {
    color: var(--gray-color) !important;
  }
  .gap {
    margin: 10px 0 !important;
    display: block;
    color: var(--text-color);
  }
  .submitButton {
    font-size: 16px;
    max-width: 320px !important;
  }
  .p-30 {
    padding: 30px 50px;
  }

  div.btn {
    display: inline;
    .btnCopy {
      background: $lb;
      color: #fff;
      border: 1px solid $lb;
      outline: none;
      cursor: pointer;
      font-size: 14px;
      padding: 6px 20px;
      display: inline-block;
      text-align: center;
      margin-left: 10px;
      border-radius: 4px;
    }
  }
  .m-1 {
    margin-bottom: 1em !important;
  }
}
.modalFooter {
  font-size: 13px;
  text-align: center;
  color: var(--secondary-color);
  padding: 13px 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: var(--background-misc);
  border-radius: 0 0 10px 10px;
  p {
    margin: 0;
    a {
      text-decoration: none;
    }
    span {
      color: var(--primary-color) !important;
    }
  }
}
.inputform {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 50%;
  border: none;
  padding: 5px;
  appearance: none;
  background: none;
  text-align: right;
  display: inherit;
  border-radius: 4px;
  color: var(--text-color);
  &:focus {
    outline: none;
    // background-color: var(--background-color);
  }
}
.verify {
  @include mobile {
    padding-top: 0 !important;
  }
  .MuiGrid-container {
    // margin: 10px 0 !important;
    > .MuiGrid-item {
      input,
      input:focus {
        box-sizing: border-box;
        outline: none;
        padding: 11px;
        border-radius: 8px;
        border: 1px solid var(--background-color);
        font-size: 12px !important;
        resize: none;
        color: var(--text-color);
        background: var(--input-bg);
        border-color: transparent;
        width: 100%;
        &::-webkit-input-placeholder {
          color: var(--placeholder);
        }
      }
    }
    .submitButton {
      width: 90%;
      max-width: 250px !important;
    }
  }
  .gray {
    color: var(--gray-color) !important;
  }
  .gap {
    margin: 10px 0 !important;
    display: block;
    color: var(--text-color);
  }
  .react-share__ShareButton {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
      svg {
        width: 50px;
        height: 50px;
      }
    }
    :focus {
      outline: none;
      border-color: transparent;
    }
  }
  .p10 {
    padding: 5px 0 5px;
  }
  .user-verify .url-btn {
    border: 1px solid #32c5ff;
    border-radius: 50px;
    color: #32c5ff;
    padding: 5px 20px;
    font-size: 12px;
    margin-top: 1em;
    background-color: transparent;
    display: block;
    margin: 0 auto;
    span {
      line-height: 1.7;
    }
  }
  .social-right {
    text-align: center;
    button {
      &:focus {
        outline: none;
        border: none;
        outline: 0;
      }
      .instaSvg {
        width: 50px;
        height: 50px;
      }
    }
  }
}
