@import "../../../assets/scss/mixin.scss";

.profile-image-option {
  .MuiPaper-root {
    background: var(--background-color-contrast);
    color: var(--text-color);
    padding: 10px 20px;
    border-radius: 0;
    //margin-top: 100px !important;
    //top: 250px !important;
  }
  .MuiList-root {
    padding: 0;
    .MuiButtonBase-root {
      font-size: 14px;
      padding: 8px 0;
      cursor: pointer;
      line-height: 1;
      &:hover {
        background-color: transparent;
      }
      // &:last-child{
      //     margin-bottom: 5px;
      // }
    }
  }
}
.profileImage {
  button {
    @include mobile {
      pointer-events: none;
    }
  }
  img {
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 10px;
  }
  .history-btn{
    border: 1px solid #32c5ff;
    border-radius: 50px;
    color: #32c5ff;
    padding: 5px 20px;
    font-size: 12px;
    margin-top: 1em
  }
}
.changePhotoProfile {
  font-size: 12px;
  color: var(--primary-color);
  margin-left: 0px;
  cursor: pointer;
}
.loader {
  position: absolute;
  left: 30%;
  top: 30%;
}

//ipad specific css (ipad mini to ipad air)
@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
  .profileImage {
    img {
      max-width: 90px;
      max-height: 90px;
    }
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  .profileImage {
    img {
      max-width: 60px;
      max-height: 60px;
    }
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
  .profileImage {
    img {
      max-width: 90px;
      max-height: 90px;
    }
  }
}
