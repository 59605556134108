@import "../../assets/scss/mixin.scss";

.preference {
  background-color: var(--background-color-contrast);
  border-radius: 10px;
  margin-top: 0;
  @include mobile {
    margin: 0 !important;
  }
  .pref-btn.txt-lft {
    text-align: left !important;
    padding-bottom: 30px;
    &.prefbtn {
      margin-left: 0;
      .spl-btn {
        background-color: var(--background-color);
        border-radius: 4px;
        color: var(--search-filter-bar-text-color);
        width: auto !important;
        box-shadow: none;
        margin: 0 2px;
        &:hover {
          background-color: var(--background-color) !important;
          box-shadow: none;
        }
        &.active {
          background-color: var(--search-filter-bar-select-item-background-color);
          color: var(--search-filter-bar-select-item-text-color);
          &:hover {
            background-color: var(--search-filter-bar-select-item-background-color) !important;
            color: var(--search-filter-bar-select-item-text-color) !important;
          }
        }
      }
    }
  }
  .profileImage {
    max-width: 120px !important;
    max-height: 120px !important;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px 4px #ddd;
    background-image: url(https://marvel-live.freetls.fastly.net/canvas/2020/6/dd76fe924c104487bb649d3792df50d7?quality=95&fake=.png);
  }
  .modalTitle {
    margin-top: 0;
  }
}
.profileSection {
  display: block;
  width: 100%;
  position: relative;
  .profileImageSection {
    width: 140px;
    text-align: left;
    display: inline-block;
    position: relative;
    vertical-align: top;
    .profileImage {
      display: block;
    }
  }
  .profileDetailSection {
    width: calc(100% - 140px);
    display: inline-block;
  }
}

@media only screen and (max-device-width: 480px) {
  .pref-container {
    width: 90%;
    .preference {
      padding: 12px;
    }
  }
}
