@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.NavItem .svg-icon.catpin .primary {
  fill: var(--sidebar-icon-fill-color);
  stroke: var(--sidebar-icon-fill-color);
  stroke-width: 0.9212;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.NavItem .svg-icon.catpin .primary2 {
  stroke: var(--sidebar-icon-fill-color);
  fill: none;
  stroke-width: 0.9212;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.NavItem .svg-icon.catpinunfill .primary {
  fill: none;
  stroke: #6c7b8a;
  stroke-width: 0.9152;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
