@import "../../../../../assets/scss/mixin.scss";

.ml-10 {
  margin-left: 10px !important;
}
.drag-and-drop.upload-sec {
  // border-radius: 8px !important;
  // padding: 40px 30px !important;
  // margin: 10px;
  // cursor: pointer;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  @include mobile {
    padding: 20px !important;
    height: 100px;
    width: 100px !important;
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    background-color: var(--background-misc-mobile) !important;
    border-width: 1px !important;
    margin: 0 auto !important;
  }
  @include desktop {
    padding: 20px !important;
    height: 100px;
    width: 100px !important;
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    background-color: var(--background-misc-mobile) !important;
    border-width: 1px !important;
    margin: 0 auto !important;
  }
}

.grid-container {
  background-color: var(--background-color-contrast);
  border-radius: 4px;
  margin-bottom: 25px !important;
  @include desktop {
    background-color: var(--background-color);
  }
  .grid-section {
    padding: 15px 10px;
    border-bottom: 1px solid #e5e5e5;
    label {
      color: var(--text-color);
      @include mobile {
        font-size: 15px;
      }
    }
    .txt-rght {
      label {
        cursor: pointer;
        color: #b0b7c3;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

.select-photo {
  margin-bottom: 35px;
  .meme-grid {
    float: left;
    width: 50%;
    cursor: pointer;
    .upload-btn {
      text-align: center;
    }
  }
  label {
    color: #b0b7c3;
    font-size: 14px;
    margin-top: 5px;
  }
  .up-temp {
    padding: 20px !important;
    height: 100px;
    width: 100px !important;
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    background-color: var(--background-misc-mobile) !important;
    border-width: 1px !important;
    margin: 0 auto !important;
    cursor: pointer;
    //display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    border-style: dashed;
    outline: none;
    transition: border 0.24s ease-in-out 0s;
  }
}
.add-photo {
  text-align: center;
  label {
    color: #0a1f44;
    font-size: 14px;
  }
}
.description .grd-frm {
  padding: 15px 10px 5px !important;
}
.mobile-label {
  color: #0a1f44 !important;
  width: 100% !important;
  margin-left: 0 !important;
  text-align: center;
  display: block;
}
.not-set {
  color: #0d55cf;
  cursor: pointer;
  @include mobile {
    font-size: 15px;
  }
}
.MuiSwitch-root {
  right: -20px;
  transform: translateY(-50%);
  width: 80px !important;
  top: 5px;
  .MuiSwitch-track {
    opacity: 1;
    background-color: var(--switch-track-background);
    width: 48px;
    height: 25px;
    border-radius: 15px;
  }
  .MuiSwitch-thumb {
    height: 18px;
    width: 18px;
    margin-left: 8px;
    margin-top: 6px;
  }
  .Mui-checked {
    color: #fff !important;
    fill: #fff !important;
  }
}
.matureSwitch {
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background: var(--danger-color) !important;
    opacity: 1;
  }
  .Mui-checked {
    color: #fff !important;
    fill: #fff !important;
  }
}
.dkt {
  .not-set {
    color: #fff;
  }
}
