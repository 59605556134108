@import "../../../../assets/scss/mixin.scss";

.bottom-profile-image-option-modal {
  .MuiPaper-root {
    background-color: var(--background-color-contrast);
    margin: auto;
    padding: 15px 0 0;
    height: auto;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    @include mobile {
      text-align: left;
    }
    .image-modal-title {
      padding-bottom: 1em;
      margin-top: 0;
      color: var(--text-color);
      text-align: center;
      margin-bottom: 0 !important;
      border-bottom: 1px solid #d9d9d9;
      @include mobile {
        padding-left: 20px;
      }
    }
    .edit-icon-container {
      color: var(--text-color);
      font-size: 1.5em;
      display: block;
      // align-items: flex-start;
      // justify-content: center;
      @include mobile {
        // justify-content: left;
      }
      img {
        width: 20px;
        color: var(--text-color);
      }
      p {
        margin: 6px 0;
        font-size: 14px;
        line-height: 1.3em;
      }
      > .MuiButtonBase-root {
        cursor: pointer;
        margin: 12px;
        width: 60px;
      }
      .input-hidden {
        visibility: hidden;
        width: 0;
      }
    }
  }
}

.edit-icon-container {
  .img-option {
    width: 100% !important;
    display: block;
    padding: 12px 20px;
    border-bottom: 1px solid #d9d9d9;
    &:nth-last-child() {
      border-bottom: none;
    }
    p {
      font-size: 1.25em !important;
    }
  }
}
