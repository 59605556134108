.spl-btn {
  background-color: var(--search-filter-bar-item-background-color);
  border-radius: 4px !important;
  color: var(--search-filter-bar-text-color);
  box-shadow: none;
  font-size: 14px;
  padding: 10px 14px;
  margin-left: 3px !important;
  margin-right: 3px !important;
  &.icon {
    color: #6c7b8a;
    fill: #6c7b8a;
    font-size: 0.8rem;
    padding: 9px 12px;
    vertical-align: middle;
    background-color: transparent;
  }
  &.active {
    background-color: var(--search-filter-bar-select-item-background-color);
    color: var(--search-filter-bar-select-item-text-color);
    &:hover {
      background-color: var(--search-filter-bar-select-item-background-color) !important;
      color: var(--search-filter-bar-select-item-text-color) !important;
    }
  }
  :hover {
    background-color: transparent;
    box-shadow: none;
  }
}

.genre-dropdown {
  z-index: 100;
  background: var(--background-color-contrast);
  // top: 90px !important;
  width: 200px;
  border-radius: 4px;
  height: 250px;
  overflow-y: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  .MuiButtonBase-root {
    width: 100% !important;
    justify-content: left;
    padding: 5px 10px;
  }
}
