@import "../../../../assets/scss/mixin.scss";
@import "../../../../assets/scss/variables.scss";
.content-modal {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.98) !important;
  }
  .MuiDialog-paperFullScreen {
    background: transparent;
  }
  .contentPage {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1250;
    justify-content: center;
    display: flex;
    > .closeBtn {
      pointer-events: auto;
      z-index: $zIndex;
    }
    // background-color: #181818;
    // background: rgba(0, 0, 0, 0.98);
    .overBtnPrev {
      position: absolute !important;
      z-index: 10;
      top: auto;
      left: auto;
      width: 50%;
      height: 100%;
      // background: rgba(255, 0, 0, 0.5);
      pointer-events: auto;
      @include mobile {
        top: 0;
        left: 0;
        width: 100%;
      }
    }
    .slider-container {
      width: 50%;
      display: flex;
      justify-content: center;
      height: 100%;
      //pointer-events: none;
      margin: 0 auto;
      @include mobile {
        width: 100%;
        height: 100%;
      }
      .overMaster {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 6999;
      }
      &.comment-active {
        width: calc(50% + 300px);
        @include mobile {
          width: 100%;
          height: 100%;
        }
      }
      .slick-slider {
        background: #050505;
        width: 100%;
        // height: calc(100% - 0);
        pointer-events: none;
        max-width: 1280px;
        overflow: hidden;
        z-index: 9;
        margin: 10px 0 0 0;
        @include mobile {
          width: 100%;
          margin: 0;
          height: 100%;
        }
        &.comment-active {
          // margin: 10px 0 10px auto !important;
          width: calc(100% - 300px);
          @include mobile {
            margin: 0 !important;
            width: 0;
          }
        }
        .slick-list {
          width: 100%;
          height: 100% !important;
          position: relative;
          .slick-track {
            height: 100% !important;
            width: 100%;
            pointer-events: none;
            transition: transform 500ms ease 0s;
            .slick-slide {
              height: 100%;
              outline: 0;
              pointer-events: auto;
              .image-container {
                width: 100%;
                height: 100%;
                position: absolute;
                outline: 0;
                .back-btn {
                  // width: 60px;
                  top: 10px;
                  @include mobile {
                    background-color: #fff;
                    //z-index: 2;
                    z-index: $zIndex;
                    pointer-events: auto;

                    &::before,
                    &:after {
                      background-color: #fff;
                    }
                  }
                }

                .closeBtn {
                  right: 0;
                  top: 10px;
                  &:hover {
                    background: hsla(0, 0%, 100%, 0.2);
                  }
                }
                .image-wrapper {
                  position: relative;
                  width: 100%;
                  height: 100%;
                  background-color: #000;
                  .logoutAsound {
                    // position: relative;
                    // bottom: 30px;
                    // float: right;
                    position: absolute;
                    bottom: 70px;
                    right: 0;
                    cursor: pointer;
                    z-index: 1;
                    @include mobile {
                      right: 10px;
                    }
                    img {
                      width: 25px;
                      background-color: #282b34;
                      padding: 5px;
                      border-radius: 50%;
                      @include mobile {
                        width: 32px;
                        padding: 8px;
                      }
                    }
                  }
                  .logoutAsoundAds {
                    height: 90px;
                  }
                  .footerz {
                    z-index: 10000;
                  }
                  .detailAds {
                    // height: 99%;
                    width: 100%;
                    background-color: gray;
                    cursor: pointer;
                    z-index: $zIndex;
                    pointer-events: auto;
                    position: absolute;
                    top: calc(50% - 30px);
                    transform: translate(0, -50%);
                    height: auto;
                    max-height: calc(100% - 60px);
                    @include mobile {
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      position: absolute;
                      height: auto;
                    }
                  }
                  // @include mobile {
                  //   position: absolute;
                  //   top: 0;
                  // }
                  .detail-arrow {
                    z-index: 1;
                    position: absolute;
                    text-align: center;
                    width: 100%;
                    bottom: 32px;
                    @include mobile {
                      // bottom: 87px;
                      bottom: 20px;
                    }
                    svg {
                      position: absolute;
                      animation: bounce 1.5s;
                      animation-direction: alternate;
                      animation-timing-function: ease-in-out;
                      animation-iteration-count: infinite;
                      @include mobile {
                        right: 20%;
                      }
                      @keyframes bounce {
                        0% {
                          bottom: 8px;
                        }
                        25% {
                          bottom: 0px;
                        }
                        50% {
                          bottom: 5px;
                        }
                        75% {
                          bottom: 0px;
                        }
                        100% {
                          bottom: 2px;
                        }
                      }
                    }
                  }
                  .previousButton,
                  .nextButton {
                    svg {
                      polygon {
                        // fill: #a5a5a5;
                        fill: #fff;
                        opacity: 1;
                      }
                    }
                    &.disabled {
                      display: none;
                    }
                  }
                  .top-label {
                    font-size: 16px;
                    @include mobile {
                      color: #6c7b8a;
                      font-size: 14px;
                    }
                  }
                  .imageAds {
                    height: calc(100% - 124px) !important;
                  }
                  .desktop_img {
                    height: 90%;
                    position: relative;
                    // top: 7%;
                    // @include mobile {
                    //   top: 0;
                    // }
                    .slider {
                      height: 100% !important;
                    }
                    .slide {
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      overflow: hidden;
                      .img-container {
                        background: transparent;
                        img {
                          height: auto !important;
                          width: auto !important;
                          max-height: 80%;
                          max-width: 100%;
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          -webkit-transform: translate(-50%, -50%);
                          transform: translate(-50%, -50%);
                          object-fit: contain;
                          @include mobile {
                            width: 100% !important;
                          }
                        }
                      }
                    }
                    .album-dot {
                      bottom: 20px;
                      position: absolute;
                      left: 50%;
                      -webkit-transform: translate(-50%);
                      transform: translate(-50%);
                      height: 20px;
                      overflow: hidden;
                      z-index: 100000;
                      align-items: center;
                      max-width: 400px;
                      @include mobile {
                        bottom: 12px;
                        max-width: 250px;
                      }
                      .album-dot-item {
                        width: 6px;
                        height: 6px;
                        background: #a5a5a5;
                        border-radius: 50%;
                        margin-right: 7px;
                        display: inline-block;
                        cursor: pointer;
                        vertical-align: middle;
                        &.active {
                          width: 10px;
                          height: 10px;
                          background-color: #fff;
                        }
                        &:last-child {
                          margin-right: 0;
                        }
                      }
                    }
                  }
                  // .img_height {
                  //   height: 68%;
                  // }
                  .image-icon-text {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: auto;
                    color: #fff;
                    z-index: $zIndex;
                    pointer-events: auto;

                    // padding: 0 15px;
                    @include mobile {
                      // bottom: 30px;
                      // padding: 0 10px 0 5px;
                    }
                    .MuiGrid-container {
                      margin: 20px 0;
                      @include mobile {
                        margin: 20px 0 30px;
                      }
                      .right-align {
                        .footer-menu button {
                          padding: 8px 0 !important;
                        }
                        > button {
                          padding: 8px 12px !important;
                          &:last-child {
                            padding: 8px 20px 8px 12px !important;
                          }
                        }
                      }
                    }
                    .image-text {
                      color: #cfcfcf;
                      margin-bottom: 30px;
                      display: none;
                      .description {
                        position: relative;
                        margin-bottom: 10px;
                        color: #606060;
                        margin-top: 10px;
                        line-height: 18px;
                        .view-more {
                          font-size: 14px;
                          letter-spacing: 0.4px;
                          line-height: 18px;
                          min-height: 18px;
                          height: auto;
                          overflow: hidden;
                          position: relative;
                          background: rgba(0, 0, 0, 0.7);
                        }
                      }
                    }
                    .img-icon {
                      display: none;
                      margin: 16px 0;
                      padding: 0;
                      &.active {
                        display: flex;
                      }
                      @include mobile {
                        flex-wrap: wrap;
                        display: flex;
                        margin: 15px 0 0;
                        justify-content: space-between;
                      }
                      li {
                        padding: 0;
                        padding-right: 30px;
                        cursor: pointer;
                        margin-right: 15px;
                        -webkit-tap-highlight-color: transparent;
                        width: auto;
                        @include mobile {
                          width: 33%;
                          height: 33px;
                          margin-right: 0;
                          margin-bottom: 15px;
                          font-size: 19px;
                          padding-left: 3px;
                          padding-right: 0;
                          display: flex;
                          justify-content: center;
                          color: #a5a5a5;
                          font-size: 15px;
                          &.s-like {
                            order: 1;
                            justify-content: left;
                          }
                          &.comment {
                            order: 2;
                          }
                          &.more-icon {
                            order: 3;
                            justify-content: flex-end;
                          }
                          &.report {
                            order: 4;
                            justify-content: left;
                          }
                          &.download {
                            order: 5;
                          }
                          &.share {
                            order: 6;
                            justify-content: flex-end;
                            padding-right: 10px;
                          }
                        }
                        img {
                          position: relative;
                          width: 16px;
                          margin-right: 10px;
                          float: left;
                          @include mobile {
                            position: relative;
                            width: 17px;
                            vertical-align: top;
                            left: 9px;
                            margin-right: 0;
                          }
                        }
                        span {
                          color: #a5a5a5;
                          font-size: 14px;
                          margin-left: 4px;
                          line-height: 14px;
                          position: absolute;
                          right: 0;
                          @include mobile {
                            position: relative;
                          }
                        }
                        &.download {
                          margin-left: auto;
                        }
                        &:last-child {
                          padding-right: 10px;
                          margin-right: 0;
                        }
                      }
                    }
                    svg.share {
                      cursor: pointer;
                    }
                  }
                  &:hover {
                    .img-icon {
                      display: flex;
                    }
                  }
                  .detail-desc {
                    font-size: 13px;
                    color: #a5a5a5;
                    position: absolute;
                    bottom: 60px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    text-align: left;
                    cursor: pointer;
                    margin: auto 15px;
                    line-height: 26px;
                    width: 80%;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    @include mobile {
                      // bottom: 115px;
                      bottom: 65px;
                      width: 65%;
                    }
                  }
                  .detail-desc.adsDesc {
                    bottom: 120px;
                  }
                  .view-more {
                    color: #a5a5a5;
                    position: absolute;
                    bottom: 65px;
                    right: 20px;
                    font-size: 13px;

                    @include mobile {
                      bottom: 70px;
                      right: 15px;
                    }
                  }
                  .view-more.adsView {
                    bottom: 125px;
                  }
                }
              }
            }
          }
        }
      }
      .comment-container {
        padding: 10px 0 5px 15px;
        color: #fff;
        width: 300px;
        overflow-y: auto;
        overflow: scroll;
        height: 100%;
        overflow-x: hidden;
        // background: #131313;
        margin-right: auto;
        margin-left: 0;
        overflow: hidden;
        // height: calc(100% - 20px);
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        pointer-events: auto;
        @include mobile {
          background: var(--background-color);
          padding: 0;
          width: 100%;
          height: 100%;
          margin: 0;
        }
        /* template class */

        &.comment-animate {
          animation: modalInM 0.5s;
          @include mobile-and-gplands {
            animation: modalIn 0.5s;
          }
        }

        @-webkit-keyframes modalIn {
          from {
            transform: translateY(50%);
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        @keyframes modalIn {
          from {
            transform: translateY(50%);
            opacity: 0.5;
          }

          to {
            opacity: 1;
          }
        }

        @keyframes modalInM {
          from {
            transform: translateX(50%);
            opacity: 0.5;
          }

          to {
            opacity: 1;
          }
        }

        @-webkit-keyframes modalOut {
          from {
            opacity: 1;
          }

          to {
            transform: translateY(50%);
            opacity: 0;
            display: none;
          }
        }

        @keyframes modalOut {
          from {
            opacity: 1;
          }

          to {
            transform: translateY(50%);
            opacity: 0;
            display: none;
          }
        }
      }
    }
  }
}
.session-detail {
  z-index: 10;
  position: absolute;
  text-align: center;
  margin-top: 0;
  width: auto;
  padding: 6px 10px 0;
  background-color: #000;
  a {
    text-decoration: none;
    color: #ccc;
    margin-right: 15px;
    font-size: 14px;

    float: left;
    line-height: 40px;
    svg {
      vertical-align: middle;
    }
    &::last-child {
      margin-right: 0;
    }
  }
}
.session-container {
  height: 95% !important;
  margin-top: 5%;
  @include mobile {
    margin-top: 20%;
    .image-wrapper {
      @include mobile {
        height: 96% !important;
      }
      .image-icon-text {
        bottom: 20px !important;
        .footer-detail-content {
          margin-bottom: 0;
        }
      }
    }
  }
}
