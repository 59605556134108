@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.NavItem .svg-icon.language-web .primary {
  fill: none;
  stroke-width: 1.325;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 4.1;
  stroke: var(--main-color);
}
.NavItem .svg-icon.language-web .secondary {
  fill: none;
  stroke-width: 1.325;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--main-color);
}

.NavItem.NavItemLanguage:hover .svg-icon.language-web .primary {
  fill: none;
  stroke-width: 1.325;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 4.1;
  // stroke: #6394b4;
}

.NavItem.NavItemLanguage:hover .svg-icon.language-web .secondary {
  fill: none;
  // stroke: #6394b4;
  stroke-width: 1.325;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.NavItem.NavItemLanguage.active .svg-icon.language-web .primary {
  fill: none;
  stroke-width: 1.325;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 4.1;
  stroke: #fff;
}

.NavItem.NavItemLanguage.active .svg-icon.language-web .secondary {
  fill: none;
  stroke: #fff;
  stroke-width: 1.325;
  stroke-linecap: round;
  stroke-linejoin: round;
}
