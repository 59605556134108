@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.svg-icon.like-inactive .primary {
  fill: none;
  stroke: var(--main-card-color);
}

.svg-icon.like-inactive-content .primary {
  fill: none;
  stroke: #6c7b8a;
}

.svg-icon.like-active .primary {
  fill: #de432f;
  stroke: #de432f;
  stroke-width: 0.23;
}
