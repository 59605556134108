@import "../../../assets/scss/mixin.scss";

.img {
  font-size: 14px;
  label.bold {
    display: inline-block;
    width: 50%;
    font-size: 16px;
  }
  .delete-img {
    display: inline-block;
    text-align: right;
    width: 50%;
    &:hover {
      background-color: transparent !important;
    }
  }
}

.step-one-images {
  @include mobile {
    width: 98%;
    margin: 0 auto;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .vdo > div {
    width: 100% !important;
  }
  .img {
    @include mobile {
      height: 100px;
      min-width: 100px;
      position: relative;
      margin: 0 5px;
    }

    .delete-video {
      display: inline;
      position: absolute;
      color: #fff;
      background: transparent;
      outline: none;
      border: none;
      right: 0;
      z-index: 10;
    }
    div {
      @include mobile {
        overflow: hidden;
      }
    }
    video {
      height: auto !important;
      @include mobile {
        height: 100px !important;
        width: auto !important;
      }
    }
  }
}
.autoHeight {
  height: auto !important;
  width: 100% !important;
  margin-bottom: 10px;
}
.slider a.previousButton,
.slider a.nextButton {
  top: 50% !important;
}
