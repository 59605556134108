@import "../../../../assets/scss/mixin.scss";

@media only screen and (max-width: 768px) {
  ul.suggestion {
    left: 0px !important;
    width: 100% !important;
  }
  .custom-search {
    @include mobile {
      background-color: #fff !important;
    }
    input {
      padding-left: 25px;
      width: 100% !important;
    }
  }
}
.dkt {
  .custom-search {
    @include mobile {
      background-color: #282b34 !important;
    }
  }
}
.searchfor {
  display: flex;
  align-items: center;
  .query{
    cursor: pointer;
  }
  .discover-m {
    vertical-align: middle;
    background-color: #32c5ff;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    padding: 6px;
    margin-left: 4px;
    #Shape {
      fill: #fff !important;
      stroke-width: 1px;
      stroke: #fff;
    }
    .search-icon {
      vertical-align: middle;
      background-color: #32c5ff;
      border-radius: 50%;
      font-size: 38px;
      padding: 5px;
      svg {
        stroke: #fff !important;
      }
    }
  }
}
