@import "../../../../assets/scss/variables.scss";

.alert-modal {
  .MuiDialog-paperWidthSm {
    height: auto;
    width: 360px;
    border-radius: 20px;
    min-width: 200px;
    min-height: 130px;
    max-height: 220px;
    padding: 22px 30px;
    background-color: var(--background-color-contrast);

    .MuiDialogTitle-root {
      padding: 0;
      h2 {
        font-size: 20px;
        color: var(--text-color);
        line-height: 1em;
      }
    }
    .MuiDialogContent-root {
      padding: 0;
      margin: 16px 0;
      overflow: hidden;
      p {
        font-size: 14px;
        color: var(--text-color);
        line-height: 23px;
        margin: 0;
        width: 100%;
        span {
          width: 100%;
        }
      }
    }
    .MuiDialogActions-spacing {
      text-align: center;
      padding: 0 10px;
      max-width: 100%;
      margin-top: 15px;
      button {
        color: $lp;
        font-size: 13px;
        margin: 0 15px;
        // padding: 0;
        line-height: 1em;
        min-width: auto;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
