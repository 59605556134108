@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.svg-icon.gif .primary {
  fill: $de;
}

