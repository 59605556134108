@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.svg-icon.bell .primary {
  // stroke: #707070;
  stroke: var(--main-color);
  fill: none;
  stroke-miterlimit: 10;
}
.footer-navigation .svg-icon.bell .primary {
  fill: none;
  stroke: #707070;
}
.active .svg-icon.bell .primary {
  stroke: $lp;
  fill: $lp;
  stroke-miterlimit: 10;
}

.dkt .footer-navigation .active .svg-icon.bell .primary {
  stroke: #eee;
  fill: #eee;
}
