@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

.mobilecontact {
  .sub-header {
    display: none;
  }
  .my-20 {
    input,
    .mobiletf {
      background-color: #f6f6f6 !important;
    }
  }
}
