@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.web-more-link .svg-icon.report .primary {
  fill: none;
  stroke: $iw7;
  stroke-width: 2px;
}

.web-more-link .svg-icon.report .secondary {
  fill: $iw7;
}
.svg-icon.report .primary {
  fill: none;
  stroke: #a5a5a5;
  stroke-width: 3px;
}

.svg-icon.report .secondary {
  fill: #a5a5a5;
}
