@import "../../..//assets/scss/mixin.scss";

.largeIcon {
  font-size: 2.15em !important;
}
.menuIcon {
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  padding: 4px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  @include mobile {
    padding: 2px;
  }
}
.sectionMobile {
  @include mobile {
    display: flex;
    z-index: 2;
  }
}
.headerLogo-mobile {
  @include mobile {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    position: absolute;
    width: 100%;
  }
}
.headerRight {
  @include mobile {
    display: flex;
  }
}
