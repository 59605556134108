@import "../../../assets/scss/mixin.scss";

.menuItem {
  padding-top: 0 !important;
  line-height: 0.5 !important;
}

.right-align {
  text-align: right;
}

label {
  margin-left: 10px;
}

.footer-menu {
  display: inline;
  vertical-align: middle;
  margin-right: 50px;
  color: #6c7b8a;
  .soundicon span {
    height: 22px;
    width: 22px;
  }
  .mute-unmute-icon {
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
  @include mobile {
    margin-right: 15px;
    // &:last-child {
    //   padding: 0 20px 0 12px !important;
    // }
    label {
      margin-left: 2px;
      @include mobile {
        font-size: 14px;
      }
    }
  }
  svg {
    vertical-align: middle;
    cursor: pointer;
    #Path,
    #Shape {
      stroke: #6c7b8a;
      @include mobile {
        stroke: #6c7b8a;
      }
    }
    circle.primary {
      fill: #6c7b8a !important;
      @include mobile {
        fill: #6c7b8a !important;
      }
    }
  }
  .volume-bar-wrapper {
    display: block;
    width: 120px;
    justify-content: space-between;

    // @include mobile {
    //   display: none;
    // }
    &__range {
      width: 200px;
      display: inline;
      align-items: center;

      &:hover {
        input[type="range"] {
          width: 95px;
          margin-right: 5px;
        }

        outline: none;

        input[type="range"]::-webkit-slider-thumb {
          width: 15px;
          // visibility: visible;
        }

        input[type="range"]::-moz-range-thumb {
          width: 15px;
          // visibility: visible;
        }
      }

      .mute-unmute-icon {
        width: 21px;
        height: 21px;
        cursor: pointer;
      }

      input[type="range"] {
        // width: 100px;
        width: 0px;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: scale-horizontal;
        background: transparent;
        padding: 5px 0;
        padding-right: 5px;

        transition: width 0.2s ease-in-out;
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
      }

      input[type="range"]:focus {
        outline: none;
      }

      input[type="range"]::-ms-track {
        width: 100%;
        cursor: pointer;

        background: transparent;
        border-color: transparent;
        color: transparent;
      }

      input[type="range"]::-webkit-slider-thumb {
        // visibility: hidden;

        -webkit-appearance: none;
        transition: width 0.1s ease-in-out;
        /* border: 1px solid #000000; */
        height: 15px;
        // width: 15px;
        width: 0px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
        margin-top: -6px;
        /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;*/
      }

      /* All the same stuff for Firefox */
      input[type="range"]::-moz-range-thumb {
        // visibility: hidden;

        -webkit-appearance: none;
        transition: width 0.1s ease-in-out;
        /* border: 1px solid #000000; */
        height: 15px;
        // width: 15px;
        width: 0px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
        margin-top: -6px;
        /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;*/
      }

      /* All the same stuff for IE */
      input[type="range"]::-ms-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #000000;
        height: 36px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
      }

      input[type="range"]::-webkit-slider-runnable-track {
        /* width: 100%; */
        height: 4px;
        cursor: pointer;
        /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
        background: #6c7b8a;
        border-radius: 1.3px;
        // border: 0.2px solid #010101;
      }

      // input[type="range"]:focus::-webkit-slider-runnable-track {
      //   background: #367ebd;
      // }

      input[type="range"]::-moz-range-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        background: #6c7b8a;
        border-radius: 1.3px;
        // border: 0.2px solid #010101;
      }

      input[type="range"]::-ms-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
      }

      input[type="range"]::-ms-fill-lower {
        background: #6c7b8a;
        // border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      }

      input[type="range"]:focus::-ms-fill-lower {
        background: #6c7b8a;
      }

      input[type="range"]::-ms-fill-upper {
        background: #6c7b8a;
        // border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      }

      input[type="range"]:focus::-ms-fill-upper {
        background: #6c7b8a;
      }
    }
  }
  .thumb {
    width: 16px;
    height: 16px;
    position: absolute;
    background-color: var(--primary-color);
    border-radius: 50%;
    top: 50%;
    transform: translateY(-4px);
    left: 14.593161275275362%;
  }

  .show-range {
    input[type="range"] {
      width: 100px !important;
    }

    outline: none;

    input[type="range"]::-webkit-slider-thumb {
      width: 15px !important;
    }
  }
  @include mobile {
    .volume-bar-wrapper__range {
      input[type="range"] {
        width: 60px !important;
      }
    }
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .volume-bar-wrapper__range {
      .mute-unmute-icon {
        position: absolute;
        right: 0;
      }
    }
  }
  .video-volume-bar {
    padding: 10px 0 !important;
  }
}

.footer-detail-content {
  background-color: #282b34;
  padding: 13px 0 10px 0;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  @include mobile {
    padding: 10px 0 10px 0;
  }
  > .pointer {
    margin-top: 5px;
    @include mobile {
      margin-top: 7px;
    }
  }
}
