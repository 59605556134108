@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

:root {
  // --background-color: #f9f9f9;
  // --main-header-bg-color: #fff;
  // --background-color-contrast: #efefef;
  // --background-color-contrast: #fff;
  // --main-modal-footer-color: #f5f5f5;
  // --main-modal-secondary-bg-color: #fff;
  // --main-card-bg-color: #fff;
  // --main-color: #aaa;
  // --main-card-color: #606060;
  // --grey-white: #707070;
  // --svg-sidebar-color: #505050;
  // --modal-menu-btn-bg-color: #4b4b4b;
  // --modal-menu-btn-color: #eee;
  // --menu-btn-border: #efefef;
  // --menu-btn-color: #606060;
  // --menu-btn-bg-active: #efefef;
  // --menu-btn-color-active: #707070;
  // --input-text: #707070;
  // --select-count-lang-btn-color: #707070;
  // --select-count-lang-btn-bg: #efefef;
  // --black-font: #000;

  //New defined variable
  //Color variables defined in funcs.js
  --sidebar-width: 186px;
  --grid-width: 90%;
  --grid-margin-left: calc(((100% - var(--sidebar-width)) - var(--grid-width)) / 2);
  --search-filter-bar-width: 80%;
  // --search-filter-bar-width: calc(90% - 40px);
  --search-filter-bar-margin-left: calc(((100% - var(--sidebar-width)) - var(--search-filter-bar-width)) / 2);
  --tymoff-container-width: 65%;
  --tymoff-container-margin-left: calc(((100% - var(--sidebar-width)) - var(--tymoff-container-width)) / 2);

  @include mobile {
    --grid-width: 96%;
    --grid-margin-left: 2%;
    --tymoff-container-width: 90%;
    --tymoff-container-margin-left: 5%;
  }
}

.dkt {
  * {
    // box-shadow: none !important;
    // border-color: transparent !important;
  }
  *:focus {
    outline: none;
  }
}

* {
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
}

input,
input:before,
input:after,
textarea,
textarea:before,
textarea:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  -o-user-select: initial;
}

html,
body {
  margin: 0;
  width: 100%;
  padding: 0 !important;
  background-color: var(--background-color);
  font-family: "Lato", sans-serif !important;
  // overflow-x: hidden;
  // overflow-y: auto;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  @include mobile {
    width: 3px;
    display: none;
  }
}

::-webkit-scrollbar-thumb {
  background: var(--verScrollBtn);
  border-radius: 5px;
  @include mobile {
    display: none;
  }
}

::-webkit-scrollbar-track {
  background: var(--verScrollBg);
  @include mobile {
    display: none;
  }
}

.container {
  position: relative;
  top: 70px;
  background: var(--background-color);
  @include desktop {
    -webkit-transition: 0.26s ease-in-out !important;
    transition: 0.26s ease-in-out !important;
  }
  @include mobile {
    top: 50px;
  }
}

.drawerOpen {
  width: calc(100% - var(--sidebar-width));
  left: var(--sidebar-width);
  background-color: var(--background-color);
  @include mobile {
    left: 0;
    width: 100%;
    padding-top: 0;
    top: 30px;
    margin-bottom: 50px;
    // overflow: hidden;
  }
}
.drawerClose {
  width: calc(100% - 65px);
  left: 65px;
  @include mobile {
    left: 0;
    width: 100%;
  }
}
.desktop-hidden {
  @include desktop {
    display: none !important;
  }
}

.mobile-hidden {
  @include mobile {
    display: none !important;
  }
}

.tab-hidden {
  @include tab {
    display: none !important;
  }
}
.sub-header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  background: var(--search-filter-bar-background-color);
  z-index: 4;
  padding: 0 15px;
  @include mobile {
    padding: 0;
  }
  &.text-left {
    justify-content: left;
    padding-left: 50px;
    @include mobile {
      padding-left: 0;
    }
  }
  &.space-between {
    padding-left: 50px;
    justify-content: space-between;
  }
  h1 {
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 18px;
    color: var(--text-color);
    position: absolute;
  }
  .back-btn {
    left: 20px;
  }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

/* prevent scroll */
.prevent-scroll {
  @include mobile {
    overflow: hidden;
    overscroll-behavior: contain;
    position: fixed;
  }
}

//==========================================================================================================================
//==========================================================================================================================
//==========================================================================================================================
//==========================================================================================================================
// --------------------- My Classes ---------------------
.tymoff-container {
  width: var(--tymoff-container-width);
  margin-left: var(--tymoff-container-margin-left);
  position: relative;
  // padding: 40px;

  padding: 25px 40px;
  display: block;
  border-radius: 10px;
  color: var(--text-color);
  background-color: var(--background-color-contrast);
  height: auto !important;
  margin-bottom: 100px;
  @include mobile {
    padding: 13px;
    margin-bottom: 100px;
    background-color: transparent;
    margin: 0;
    width: 100%;
    min-height: 90vh !important;
  }
  .my-20 {
    margin-top: 0 !important;
    // margin-top: 20px !important;
    margin-bottom: 15px !important;
  }
  .grid {
    width: 96%;
    margin: 0 auto !important;
  }
}
.m-0 {
  margin: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-10 {
  @include mobile {
    margin-bottom: 10px;
  }
}
.txt-lft {
  text-align: left;
}
.txt-rght {
  text-align: right;
}
.txt-cntr {
  text-align: center;
}
.font-bold {
  font-family: "Lato-Bold", sans-serif !important;
}
.grid-discover {
  margin-top: 0;
  // width: var(--grid-width) !important;
  // margin-left: var(--grid-margin-left);
  // margin: 0 auto 25px;
  position: fixed;
  background-color: var(--background-color);
  z-index: 1;
  padding: 15px 0;
  width: 100% !important;
  top: 56px !important;
  @include mobile {
    top: 50px !important;
    padding: 15px 0 0 0 !important;
  }
  &.search-mobile {
    @include mobile {
      top: 110px !important;
      padding-bottom: 10px !important;
      //top: 50px !important;
    }
  }
  .filters {
    width: 80%;
    @include mobile {
      // width: 65%;
      width: auto;
    }
  }
}
h1 {
  &.heading {
    @extend .font-bold;
    // font-size: 38px !important;
    font-size: 32px !important;

    @include mobile {
      font-size: 30px !important;
      text-align: center;
    }
  }
}
h3 {
  &.title {
    @extend .font-bold;
    font-size: 16px !important;
  }
}
.relative {
  position: relative !important;
}
a {
  color: var(--secondary-color) !important;
}
.primary-color {
  color: var(--primary-color) !important;
}
.bottom-selected {
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}
.user-non-login {
  width: 30px;
  height: 30px;
  background-color: royalblue;
  border-radius: 50%;
  overflow: hidden;
}
.pointer {
  cursor: pointer !important;
}
.f-1-2 {
  @include mobile {
    font-size: 1.2em !important;
  }
}
.radioClass {
  color: var(--text-color);
}
.profile-filter {
  padding: 5px 0;
}
.profile-filter2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  @include mobile {
    width: 100%;
    align-items: flex-start;
  }
  label {
    width: auto !important;
    color: var(--text-color);
    padding-left: 20px;
  }
  .filters {
    width: auto !important;
  }
}
.submitButton {
  height: 45px !important;
  &.disable-btn {
    background: #d5d5d5 !important;
  }
  &.small {
    @include mobile {
      font-size: 14px !important;
      padding: 10px !important;
      height: auto !important;
    }
  }
}
.singleLineName {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
// --------------------- Material class overwrites ---------------------
.MuiInputBase-root {
  background-color: var(--input-bg);
  color: var(--text-color) !important;
  border: none;
  @include mobile {
    background-color: var(--background-color-contrast) !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-width: 0;
  }
  .MuiOutlinedInput-input {
    padding: 11px;
  }
}
.MuiBadge-badge {
  border-radius: 5px !important;
  font-size: 0.45rem !important;
  min-width: 16px !important;
  height: 16px !important;
  padding: 0 !important;
  top: 10px !important;
  left: 5px !important;
}
.MuiIconButton-root {
  &.br-1 {
    border-right: 1px solid #eee !important;
    border-left: 1px solid #eee !important;

    border-radius: 0 !important;
    margin: 10px 0 10px 0 !important;
    width: 55px !important;
    height: 34px !important;
    padding: 0;
    @include mobile {
      border: none !important;
      width: 40px !important;
    }
  }
  &.bl-1 {
    border-left: 1px solid #eee !important;
    // border-right: none !important;
    @include mobile {
      border: none !important;
    }
  }
}
.dkt {
  .MuiIconButton-root {
    &.br-1 {
      border-right: 1px solid #555 !important;
      border-left: 1px solid #555 !important;
      @include mobile {
        border: none !important;
      }
    }
    &.bl-1 {
      border-left: 1px solid #555 !important;
      @include mobile {
        border: none !important;
      }
    }
  }
}
.MuiOutlinedInput-root {
  border-radius: 8px !important;
}
.MuiSelect-icon {
  top: 0 !important;
  color: #525252 !important;
  font-size: 3.5em !important;
}
.MuiButton-root {
  text-transform: initial !important;
}
.MuiButton-root:hover,
.MuiListItem-button:hover {
  //text-transform: capitalize;
  background-color: transparent !important;
}
.MuiBackdrop-root {
  background-color: rgba(20, 25, 38, 0.58) !important;
  backdrop-filter: blur(10px) !important;
}
label {
  &.MuiFormControlLabel-root {
    margin-right: 16px !important;
  }
}
.MuiRadio-root {
  color: #e6e6e6 !important;
}
.Mui-checked {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
  .MuiSvgIcon-root:nth-child(2) {
    transform: scale(2) !important;
  }
}
.MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
  @include mobile {
    width: 0.6em !important;
    height: 0.6em !important;
  }
}
.MuiSnackbar-root {
  width: 100%;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  border-radius: 0 !important;
  display: block !important;
}

/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
.spinner {
  // width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

//ipad specific css (ipad mini to ipad air)
@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
  .tymoff-container {
    width: 90%;
    margin-left: 5%;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  .tymoff-container {
    width: 90%;
    margin-left: 5%;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
  .tymoff-container {
    width: 90%;
    margin-left: 5%;
  }
}
