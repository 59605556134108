@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.svg-icon.play .primary {
  // fill: rgba(0,0,0,0.8);
  // stroke: #000;
  // stroke-miterlimit: 10;
}

.svg-icon.play .secondary {
  fill: $bgColor;
  // stroke: $bgColor;
  stroke-miterlimit: 10;
}
