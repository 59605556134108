@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixin.scss";

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  &.backdrop-transparent {
    background-color: transparent;
  }
  .paper {
    background: var(--background-color-contrast);
    border-radius: 15px;
    min-height: 300px;
    margin: 1rem;
    position: relative;
    min-width: 200px;
    max-width: 720px;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    justify-content: center;
    @include mobile {
      // height: 100% !important;
      width: 100vw !important;
      margin: 0;
      border-radius: 8px;
      overflow: hidden;
    }
  }
  .paper:focus {
    outline: none;
  }

  .w-50 {
    width: 50%;
    display: inline-block;
    text-align: center;
  }

  .modalContent {
    border-radius: 20px;
    padding: 20px 20px;
    outline: 0 !important;
    @include mobile {
      padding: 0;
    }
    .modalBody {
      button {
        outline: none;
      }
    }
  }
  .modalBody {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // padding: 3% 2%;
    .ShareModal-redioBtn-290 button {
      outline: 0;
    }
  }
  .modalHeader {
    margin-bottom: 15px;
    text-align: center;
    @include mobile {
      height: 50px;
      line-height: 52px;
      margin: 0;
      // text-align: left;
      // padding-left: 50px;
    }
  }
  .modalTitle {
    margin: 0;
    margin-bottom: 5px;
    font-size: 20px;
    color: var(--text-color);
    @include mobile {
      text-align: center;
    }
  }
  .modalFooter {
    text-align: center;
    left: 0 !important;
  }
  button {
    &.submit {
      background: $lp;
      color: #fff;
      border: 1px solid $lp;
      outline: 0;
      cursor: pointer;
      margin-top: 25px;
      font-size: 14px;
      border-radius: 4px;
      height: 35px;
      @include mobile {
        height: 45px !important;
      }
    }
  }
}
/*common back-btn */
.back-btn {
  z-index: 1;
  position: relative;
  top: 0;
  left: 20px;
  background: var(--back-btn-color);
  border: none;
  color: #fff;
  width: 20px;
  height: 2px;
  font-size: 0;
  border-radius: 10px 0 0 10px;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  @include mobile {
    // top: 0;
  }
  &::before {
    content: "";
    position: absolute;
    left: -1px;
    width: 12px;
    height: 2px;
    background: var(--back-btn-color);
    transform: rotate(-45deg);
    top: -4px;
  }
  &::after {
    content: "";
    position: absolute;
    left: -1px;
    width: 12px;
    height: 2px;
    background: var(--back-btn-color);
    transform: rotate(45deg);
    top: 4px;
  }
  // &:hover {
  //   background: #eee;
  // }
}
/*common close btn*/
.closeBtn {
  z-index: 1;
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  width: 28px;
  height: 28px;
  outline: 0;
  cursor: pointer;
  border-radius: 50%;
}

.closeBtn:before {
  content: "";
  position: absolute;
  left: 5px;
  width: 18px;
  top: 13px;
  height: 2px;
  background: #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.closeBtn:after {
  content: "";
  position: absolute;
  left: 5px;
  width: 18px;
  top: 13px;
  background: #fff;
  height: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
// .closeBtn:hover {
//   background: #eee;
// }

.b-grey {
  background: #ddd !important;
  border-color: #ddd !important;
  color: $d5 !important;
  &:hover {
    border-color: #efefef;
  }
}
input::-webkit-input-placeholder {
  color: #c3c3c3;
}

.dialog-modal {
  .MuiPaper-root {
    padding: 20px 30px;
    width: 360px;
    border-radius: 20px;
    color: var(--text-color);
    background-color: var(--background-color-contrast);
    .MuiDialogTitle-root {
      padding: 0;
      .MuiTypography-root {
        font-size: 18px;
        color: var(--text-color);
        line-height: inherit;
      }
    }
    .modal-footer {
      font-size: 13px;
      text-align: center;
      color: #707070;
      padding: 0 10px;
      max-width: 100%;
      margin: 15px 0 0;
      .MuiButtonBase-root {
        font-size: 13px;
        color: $lp;
        // padding: 0;
        padding: 6px 8px;
        line-height: inherit;
        background: none;
        text-transform: uppercase;
        &:hover {
          color: #5393cf;
        }
      }
    }
  }
}
.dkt {
  .modal {
    .modalTitle {
      color: var(--text-color);
    }
    input {
      background: #555;
      border-color: #555;
      color: #eeeeee;
    }
  }
  .closeBtn,
  .back-btn {
    &:before,
    &:after {
      background: var(--back-btn-color);
    }
  }
  .scanTitle {
    color: #aaa;
  }
  .withMobileNumber {
    h3 {
      color: #aaa !important;
    }
  }
  .enterOtp {
    color: #aaaaaa;
  }
}
