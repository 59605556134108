@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

.grid {
  position: relative !important;
  // background-color: #f9f9f9;
  // margin: 0 auto;
  // margin-left: var(--grid-margin-left);
  width: var(--grid-width);
  z-index: 0;

  .card-style {
    background-color: var(--background-color-contrast) !important;
    position: relative;
    // width: 240px;
    min-height: 100px;
    height: auto !important;
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    background-color: var(--main-card-bg-color);
    @include mobile {
      width: 98%;
      margin: 3px 2px;
    }

    .rectangle {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%);
      width: 100%;
      height: 85px;
      top: 0;
      position: absolute;
      z-index: 2;
      border-radius: 10px 10px 0 0;
      padding: 10px;
      color: #fff;
      text-align: left;
      .my-0 {
        margin: 0 !important;
      }
      .oval {
        background-size: 100%;
        border-radius: 100%;
        width: 28px;
        height: 28px;
        display: inline-block;
        background-color: #000;
        span {
          padding-top: 7px;
          padding-left: 10px;
          position: absolute;
        }
      }
      .top-label {
        position: absolute;
        padding-top: 6px;
        padding-left: 10px;
      }
    }

    .footer-detail {
      padding: 5px;
      img {
        border-radius: 50%;
        width: 28px;
        height: 28px;
        display: inline-block;
        margin-left: 10px;
      }
      .top-label {
        position: absolute;
        padding-top: 6px;
        padding-left: 5px;
        color: #6b7b8b !important;
        font-size: 14px;
      }
    }

    .img-container {
      border-radius: 10px 10px 0 0;

      img {
        border-radius: 10px 10px 0 0;
        width: 100%;
        @include mobile {
          width: 100%;
        }
      }
    }

    .readMore {
      font-size: 13px;
      color: var(--gray-color);
      margin: 2%;
      width: 98%;
      // display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-align: left;
      cursor: pointer;
    }
    .session-content {
      position: absolute;
      bottom: 0;
      p {
        margin: 5px;
        font-size: 12px;
      }
      h5 {
        margin: 0;
        background: $lp;
        padding: 5px 15px;
        width: auto;
        color: #fff;
        border-radius: 4px;
        display: inline-block;
      }
    }
    .description {
      overflow: hidden;
      padding: 50px 20px 20px;
      border-radius: 10px 10px 0 0;
      width: 100%;
      .view-more {
        word-break: break-word;
        p {
          span,
          strong {
            color: #fff !important;
          }
        }
        span {
          background: transparent !important;
        }
      }
    }
  }
  .play-icon {
    // width: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
  }
  .brand-link {
    position: absolute;
    cursor: pointer;
    bottom: 5px;
    left: 5px;
    z-index: 2;
    height: 30px;
    width: 30px;
    background: rgba(0, 0, 0, 0.51);
    // padding: 3px 6px 5px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    svg {
      margin: 0 auto;
    }
    a {
      margin: 0 auto;
      text-decoration: none;
      color: #eee;
      display: flex;
      img {
        width: 17px;
      }
    }
  }
  .webTitle {
    color: var(--text-color);
    font-size: 13.5px;
    margin: 2%;
    margin-bottom: 18px;
    .MuiButtonBase-root {
      text-align: left;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
.dkt .card-style .description {
  color: #aaa !important;
  strong {
    color: #aaa !important;
  }
  p {
    color: #aaa !important;
    background-color: transparent !important;
    span {
      color: #aaa !important;
      background-color: transparent !important;
    }
  }
}

@media only screen and (max-device-width: 480px) {
  .grid {
    margin: 0 auto;
    .card-style {
      .img-container {
        //height: auto !important;
        img {
          // height: auto !important;
        }
      }
    }
  }
}
.color0 {
  background-color: #00c06e;
  color: #fff !important;
}
.color1 {
  background-color: #ff413b;
  color: #fff !important;
}
.color2 {
  background-color: #108bff;
  color: #fff !important;
}
.color3 {
  background-color: #857808;
  color: #fff !important;
}
.color4 {
  background-color: #00c06e;
  color: #fff !important;
}
.color5 {
  background-color: #5b53ff;
  color: #fff !important;
}
.color6 {
  background-color: #ff9c36;
  color: #fff !important;
}
.color7 {
  background-color: #45301e;
  color: #fff !important;
}
.color8 {
  background-color: #ff75ad;
  color: #fff !important;
}

.web-info {
  padding: 10px 5px;
  color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 15px;
  .link-icon {
    fill: #fff;
    height: 16px;
    position: absolute;
    cursor: pointer;
  }
  .site {
    text-align: center;
  }
  .site,
  .url {
    margin: 0;
    padding-bottom: 1px;
    font-weight: normal;
    padding: 0 20px;
    @include desktop {
      font-size: 15px;
    }
  }
  .url {
    font-weight: 400;
    padding: 5px 0;
    word-break: break-word;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.webcolor1 {
  background-color: #ff413b;
  color: #fff;
}
.webcolor2 {
  background-color: #108bff;
  color: #fff;
}
.webcolor3 {
  background-color: #fffc6e;
  color: #5f7b93;
}
.webcolor4 {
  background-color: #00c06e;
  color: #fff;
}
.webcolor5 {
  background-color: #5b53ff;
  color: #fff;
}
.webcolor6 {
  background-color: #ff9c36;
  color: #fff;
}
.webcolor7 {
  background-color: #45301e;
  color: #fff;
}
.webcolor8 {
  background-color: #ff75ad;
  color: #fff;
}

.dkt {
  .grid {
    .card-style {
      border: 1px solid var(--background-color) !important;
    }
  }
}

.album-dot {
  bottom: 20px;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  // display: flex;
  align-items: center;
  height: 20px;
  overflow: hidden;
  z-index: 1;
  max-width: 350px;
  @include mobile {
    bottom: 12px;
  }
  .album-dot-item {
    width: 3px;
    height: 3px;
    background: #a5a5a5;
    border-radius: 50%;
    margin-right: 3px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    &.active {
      width: 7px;
      height: 7px;
      background-color: #fff;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
