.lazyimg {
  // transition: opacity 2s ease;
}

.lazyimg-fade-in {
  opacity: 1 !important;
  z-index: 2 !important;
}

.lazyimg-fade-out {
  opacity: 0 !important;
  z-index: 0 !important;
}

.op-0 {
  opacity: 0;
  position: relative;
}

.op-1 {
  position: absolute;
  opacity: 1;
  // filter: blur(5px);
  overflow: hidden;
  background-size: 100% 100%;
}

.lazyimg-load {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
}
img {
  //max-width: 100%;
  //height: auto;
}
.img-container {
  width: 100%;
  position: relative;
  z-index: 1;
}
