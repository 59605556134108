@import "../../../../../assets/scss/mixin.scss";
@import "../../../../../assets/scss/variables.scss";
@import "../../../../../modules/react-phone/styles.scss";

.noAccountLinkMobile {
  .subheading {
    font-size: 13px;
    color: var(--secondary-color);
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 0;
    line-height: 18px;
    button {
      margin-top: -2px;
    }
  }
}
.inputNumberWrapper {
  display: flex;
  position: relative;
  .MuiButtonBase-root {
    @include mobile {
      // height: 45px;
    }
  }
}

.countryCode {
  // width: 180px;
  height: 45px;
  margin-top: 15px !important;
  padding: 0 10px !important;
  border: 1px solid var(--input-bg) !important;
  border-radius: 3px !important;
  background: var(--countrycode-background) !important;
  color: var(--secondary-color) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  font-size: 16px;
  @include mobile {
    width: 50%;
  }
}

.inputField {
  display: block;
  // width: calc(100% - 0);
  // width: calc(100% - 55px);
  margin: 15px 0 10px;
  height: 35px;
  width: 60%;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: none;
  // border: 1px solid var(--input-bg) !important;
  padding: 22.5px 15px;
  background: var(--input-bg);
  color: var(--text-color);
  font-size: 16px !important;
  @include mobile {
    width: 60%;
    height: 45px;
  }
  &:placeholder {
    color: var(--placeholder);
  }
  &:focus {
    outline: none;
  }
}

.para {
  font-size: 11px;
  color: var(--secondary-color);
}

.submitButton {
  background: $lb;
  color: #fff;
  outline: none;
  margin: 15px 0;
  font-size: 20px;
  padding: 14px;
  letter-spacing: 0.05em;
  display: block;
  text-align: center;
  border-style: none;
  width: 100%;
  cursor: pointer;
  // box-shadow: 0 2px 24px 0 rgba(50, 197, 255, 0.73);
  border-radius: 50px;
  @include mobile {
    height: 45px;
  }
  &.no-shadow {
    box-shadow: none !important;
  }
}

.submitButton:disabled {
  border: none;
  background-color: $lb;
}
.countryFlag {
  width: 24px;
  height: 24px;
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  img {
    margin-left: -6px;
  }
}
.loader2 {
  position: absolute;
  height: 24px !important;
  width: 24px !important;
  right: 20px;
  margin-top: 25px !important;
}
