.modal {
  .paper {
    &.install-modal {
      // height: 220px !important;
      height: auto !important;
      background: var(--background-color-contrast);
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: var(--text-color);
      width: 80% !important;
      text-align: center;
      border-radius: 10px;
      box-shadow: 3px 3px 20px #505050;
      min-height: auto;
      .closeBtn {
        top: 12px;
        right: 10px;
        &::before,
        &::after {
          background: var(--sidebar-icon-fill-color);
        }
      }
      .modalContent {
        padding: 25px 5px;
        text-align: center;
        .modalHeader {
          padding-left: 0;
          text-align: center;
        }
        .modalBody {
          padding-top: 4%;
          padding-bottom: 0;
          h4 {
            line-height: 25px;
            color: var(--text-color);
            // margin-top: 0;
            display: block;
            width: 100%;
            margin-bottom: 0;
          }
        }
        .modalFooter {
          background: none;
          position: relative;
        }
        .btn-install {
          background: var(--primary-color);
          color: #fff;
          // border: 1px solid #fff;
          border: none;
          outline: none;
          // padding: 7px 35px;
          padding: 15px 60px;
          display: block;
          text-align: center;
          margin: auto;
          border-radius: 40px;
          font-size: 17px;
          // box-shadow: 0 0 0 0.4px rgba(234, 60, 120, 0.4), 0 4px 5px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
// .closeBtn {
//   &.c2 {
//     &:after,
//     &:before {
//       background: #000 !important;
//     }
//   }
// }
// .dkt {
//   .closeBtn {
//     &.c2 {
//       &:after,
//       &:before {
//         background: #fff !important;
//       }
//     }
//   }
// }
