.m-b-25 {
  margin-bottom: 25px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.profileColor {
  display: inline-block;
  margin-right: 25px !important;
  margin-left: 0px !important;
  position: relative;
  border-radius: 14px;
  // box-shadow: 0 2px 24px 0 rgba(69, 65, 199, 0.38);
  width: 80px !important;
  height: 80px !important;
  margin-bottom: 10px;
  span {
    margin-left: 30px;
    position: absolute;
  }
}
.blue {
  background-color: #4f4bce;
  border-radius: 14px;
  box-shadow: 0 2px 24px 0 rgba(69, 65, 199, 0.38);
  width: 80px !important;
  height: 80px !important;
  color: #fff;
  margin-bottom: 10px;
}
.red {
  background-color: #ff6060;
  border-radius: 14px;
  box-shadow: 0 2px 24px 0 rgba(192, 192, 192, 0.31);
  width: 80px;
  height: 80px;
  color: #fff;
  margin-bottom: 10px;
}
.yellow {
  background-color: #f7b500;
  border-radius: 14px;
  box-shadow: 0 2px 24px 0 rgba(192, 192, 192, 0.31);
  width: 80px;
  height: 80px;
  color: #323232;
  margin-bottom: 10px;
}
.green {
  background-color: #84b452;
  border-radius: 14px;
  box-shadow: 0 2px 24px 0 rgba(192, 192, 192, 0.31);
  width: 80px;
  height: 80px;
  color: #fff;
  margin-bottom: 10px;
}
.orange {
  background-color: #fa6400;
  border-radius: 14px;
  box-shadow: 0 2px 24px 0 rgba(192, 192, 192, 0.31);
  width: 80px;
  height: 80px;
  color: #e0f700;
  margin-bottom: 10px;
}
.purple {
  background-color: #e99bff;
  border-radius: 14px;
  box-shadow: 0 2px 24px 0 rgba(192, 192, 192, 0.31);
  width: 80px;
  height: 80px;
  color: #6236ff;
  margin-bottom: 10px;
}

.checkboxes .MuiSvgIcon-root {
  display: none;
}

.profileColor .Mui-checked {
  top: -7px;
  right: -7px;
  font-size: 0.9em;
  &:before {
    content: "\2714";
    color: #fff;
    position: absolute;
    top: 10px;
    right: 15px;
  }
}

// bottomsheet

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -ms-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

@mixin transition($type, $duration) {
  -webkit-transition: $type $duration cubic-bezier(0.03, 0.72, 0.36, 0.99);
  -moz-transition: $type $duration cubic-bezier(0.03, 0.72, 0.36, 0.99);
  -ms-transition: $type $duration cubic-bezier(0.03, 0.72, 0.36, 0.99);
  -o-transition: $type $duration cubic-bezier(0.03, 0.72, 0.36, 0.99);
  transition: $type $duration cubic-bezier(0.03, 0.72, 0.36, 0.99);
}

.bottom-sheet-wrapper {
  @include transition(opacity, 500ms);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 998;
  width: 100%;
  height: 100%;
  opacity: 0;

  .bottom-sheet-block-layer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .bottom-sheet {
    @include transition(transform, 500ms);
    @include translate3d(0px, 100%, 0);
    position: absolute;
    bottom: 0px;
    z-index: 999;
    width: 100%;
    background-color: #fff;
  }

  &.hide {
    display: none;
  }

  .bottom-sheet-item {
    display: block;
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 20px 0;
    border: none;
    border-top: 1px solid #ededed;
    font-size: 16px;
    &.cancel {
      color: #d0021b;
    }
  }

  .toast {
    bottom: 110%;
  }
}

.bottom-sheet-wrapper.enter {
  opacity: 1;
  .bottom-sheet {
    @include translate3d(0px, 0px, 0);
  }
}

.profile-color {
  overflow-x: auto;
  display: block;
  white-space: nowrap;
  .checkboxes {
    display: block;
  }
}

.gray {
  font-family: "Lato" !important;
  color: #7f7f7f;
}

.dkt {
  .gray {
    font-family: "Lato" !important;
    color: #f9f9f9;
  }
}
