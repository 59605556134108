@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variables.scss";

header {
  &.appBar {
    z-index: 1201;
    // height: 56px;
    // border-bottom: 1px solid var(--h-border);
    color: #707070;
    background-color: var(--background-color-contrast);
    // @include color(color);
    justify-content: space-between;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.11);
    padding: 1px 0;

    // padding: 8px 20px 8px 20px;
    // padding: 5px 20px;
    // @include color(backgroundColor);
    @include mobile {
      padding: 10px 0;
      z-index: 3;
      height: 50px;
      border-bottom: none;
      justify-content: center;
    }
  }

  .MuiToolbarRegular {
    min-height: auto;
    padding: 0;
    justify-content: flex-start;
    width: 99%;

    .search-field {
      @include mobile {
        display: none;
      }

      input {
        font-size: 14px !important;
      }
    }
  }

  .headerLogo {
    width: 140px;
    display: flex;
    align-items: center;
    outline: none;

    a {
      outline: none;
      padding-left: 30px;
      padding-top: 5px;
    }
  }

  .username2 {
    font-size: 14px;
    color: var(--text-color);
  }

  .username {
    font-size: 16px;
    color: var(--text-color);
    cursor: pointer;
    margin-left: 10px;
    max-width: 150px;
  }

  .hamburger {
    padding: 5px 12px;
    margin-right: 13px;
    color: var(--grey-white);

    a {
      display: flex;
    }

    &:hover {
      background: none;
    }
  }

  .logo {
    width: 80px;
    margin-left: 10;
  }

  .grow {
    flex-grow: 1;
  }

  .sectionDesktop {
    display: none;

    @include tab {
      display: flex;
    }
  }

  .sectionMobile {
    display: none;

    @include mobile {
      display: flex;

      .headerLogo-mobile {
        width: 140px;
        display: flex;
        align-items: center;
        outline: none;
        margin-left: 50px;
        margin-top: 10px;
      }

      button {
        min-width: auto;

        // margin-left: 15px;
        img {
        }
      }
    }
  }

  .headerRightButtons.uploadIcon svg {
    margin-right: 5px !important;
  }

  .headerRightButtons {
    margin-right: 15px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    color: var(--text-color);
    font-size: 13.5px;
    text-transform: initial;

    svg {
      // margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-right: 0;
      border-radius: 0;
    }

    &:hover {
      background: none;
      // color: #6394b4;
    }

    .MuiButton-label {
      top: 2px;
      position: relative;

      @include mobile {
        padding: 5px;
      }
    }

    .userIcon {
      object-fit: cover;
      width: 30px;
      // margin-right: 15px;
      border-radius: 30px;
      height: 30px;
      // box-shadow: 1px 1px 1px #ccc;
      background-color: var(--profile-img-bg);
    }
  }
}

.userIcon {
  object-fit: cover;
  width: 30px;
  // margin-right: 15px;
  border-radius: 30px;
  height: 30px;
  // box-shadow: 1px 1px 1px #ccc;
  background-color: var(--profile-img-bg);
}

.header-search {
  display: none;

  .back-btn {
    z-index: 1;
    position: relative;
    top: 0;
    left: 20px;
    background: var(--back-btn-color);
    border: none;
    color: #fff;
    width: 20px;
    height: 2px;
    font-size: 0;
    border-radius: 10px 0 0 10px;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    @include mobile {
      // top: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left: -1px;
      width: 12px;
      height: 2px;
      background: var(--back-btn-color);
      transform: rotate(-45deg);
      top: -4px;
    }

    &::after {
      content: "";
      position: absolute;
      left: -1px;
      width: 12px;
      height: 2px;
      background: var(--back-btn-color);
      transform: rotate(45deg);
      top: 4px;
    }

    // &:hover {
    //   background: #eee;
    // }
    @include mobile {
      &::before,
      &:after {
        background-color: var(--back-btn-color);
      }
    }
  }
}

.MuiIconButton-root:hover {
  background: none !important;
}

.uploadBtnHeader {
  color: #fff !important;
  background-color: #32c5ff !important;
  border-radius: 100% !important;
  // margin: 10px 20px !important;
  // width: 36px !important;
  // height: 36px !important;
  margin: 12px 20px !important;
  width: 30px !important;
  height: 30px !important;

  &:hover {
    background-color: #32c5ff !important;
  }
}

.border-non-user {
  border-left: 1px solid #e5e5e5;
  width: 2px;
  height: 30px;
  margin-right: 20px;
}

.non-user {
  padding-left: 0 !important;
}

.dkt {
  .userIcon {
    box-shadow: none;
  }
}

//safari 6.1-10.0
// @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
//   .uploadBtnHeader {
//     .MuiSvgIcon-root {
//       margin-top: -6px;
//       margin-left: 1px;
//     }
//   }
// }

//safari 10.1+
@media not all and (min-resolution: 0.001dpcm) {
  .uploadBtnHeader {
    .MuiSvgIcon-root {
      margin-top: -6px;
      margin-left: 1px;
    }
  }
}
