@import "../../assets/scss/variables.scss";

.svg-icon {
  transition: 250ms;
}

.svg-icon.setting .primary {
  fill: var(--text-color);
}
.svg-icon.setting .secondary {
  fill: none;
  stroke: var(--text-color);
  stroke-width: 2.3762;
}
