.slider {
  .img {
    width: 100% !important;
    font-size: 14px;
    label.bold {
      display: inline-block;
      width: 50%;
      font-size: 16px;
    }
    .delete-img {
      display: inline-block;
      text-align: right;
      width: 50%;
      &:hover {
        background-color: rgba(0, 0, 0, 0.4) !important;
      }
    }
    img {
      width: 100%;
    }
  }
}

.step-two-images {
  width: 98%;
  margin: 10px auto;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: start;
  align-items: center;
  > div {
    cursor: pointer;
  }
  .img {
    height: 100px;
    min-width: 100px;
    position: relative;
    margin: 0 5px;
    margin-bottom: 17px;
    .edit-img {
      position: absolute;
      top: 50%;
      background: rgba(83, 224, 217, 0.577);
      border-radius: 10%;
      padding: 3px 13px;
      left: 35%;
      font-size: 13px;
      left: 50%;
      outline: 0;
      border: 0;
      z-index: 999;
      cursor: pointer;
      color: #fff;
      transform: translate(-50%, -50%);
    }
    button {
      &.delete-img {
        position: absolute;
        top: 0;
        width: 20px;
        height: 20px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        right: 0;
        outline: 0;
        border: 0;
        z-index: 999;
        cursor: pointer;

        // &:hover {
        //   background-color: hsla(0, 0%, 100%, 0.4);
        // }

        &::before {
          content: "";
          position: absolute;
          left: 4px;
          width: 12px;
          top: 9px;
          height: 2px;
          background: #eee;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        &:after {
          content: "";
          position: absolute;
          left: 4px;
          width: 12px;
          top: 9px;
          background: #eee;
          height: 2px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }

    img {
      width: 100px;
      height: 100%;
      border-radius: 4px;
      object-fit: contain;
      background-color: var(--countrycode-background);
    }
    > div {
      overflow: hidden;
      position: relative;
      background-color: #eeeeee;
      display: flex;
      align-items: center;
      justify-content: center;
      video {
        width: auto !important;
        position: absolute;
      }
    }
  }
}

.imgslider {
  img {
    width: 100% !important;
  }
}
