$d5: #505050;
$lp: var(--primary-color);
$d6: #606060;
$da: #aaaaaa;
$de: #eeeeee;
$iw7: #707070;
$iw5: #505050;
$id9: #909090;
$ida: #eeeeee;
$bgColor: #fff;
$detailpgIcon: #a5a5a5;
$lb: #32c5ff;
$zIndex: 11;

// ida:Icon Dark Active
// id9: Icon dark 909009
// iw5: Icon Web 505050
// iw7: Icon Web 707070
// de: Dark eeeeee
// da: Dark aaaaaa
// lp: Logo Pink color
$colors: (
  // Default color set
  default:
    (
      backgroundColor: #f9f9f9,
      color: #343434,
    ),
  // Dark Theme color set
  darkTheme:
    (
      backgroundColor: #282b34,
      color: #848a9c,
    )
);
